<template>
  <div class="about" :class="{ scrollbar: scrollbar }" id="commonTable">
    <el-table
      class="nowrap-tab"
      ref="multipleTable"
      :data="tableData"
      border
      @sort-change="sortChange"
      :max-height="tableHeight ? Height : null"
      :empty-text="emptyMessage"
      @selection-change="handleSelectionChange"
      @row-click="handleRowClick"
      @cell-click="handleCellClick"
      @select-all="selectAll"
      :row-class-name="tabRowClassName"
      :header-cell-style="{ background: '#fff', color: '#23282c' }"
      fit="true"
      @mouseup="isStretching"
      @select="select"
      :highlight-current-row="isHighlightCurrentRow"
    >
      <el-table-column
        v-if="selection"
        type="selection"
        width="55"
        align="center"
      ></el-table-column>
      <el-table-column
        v-if="isCheckBox"
        width="55"
        type="selection"
        align="center"
      ></el-table-column>
      <!-- 盘点计划盘点 -->
      <el-table-column
        v-if="inventoryNo"
        label="编号"
        width="50"
        type="index"
        align="center"
      ></el-table-column>
      <!-- 收货单号详情 -->
      <el-table-column
        v-if="isRecNo"
        label="收货单号详情"
        width="170px"
        align="center"
        prop="RecNo"
      >
        <template
          #default="scope"
          @click.stop="openDialog(scope.$index, tableData, 'details')"
        >
          <el-button
            @click.stop="openDialog(scope.$index, tableData, 'details')"
            type="text"
          >
            {{ tableData[scope.$index].RecNo }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column v-if="toBeCountedList" fixed width="50" align="center">
        <template #default="scope">
          <el-checkbox
            v-model="scope.row.ischecked"
            @change="changeSwitch(scope.row)"
          ></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column
        v-if="inventoryNum"
        label="取消"
        width="50"
        align="center"
      >
        <!-- 滑动radiobutton按钮样式保留 -->
        <!-- <template #default="scope">
          <el-switch
            class="tableScopeSwitch"
            @change="changeSwitch(scope.row)"
            active-text="YES"
            inactive-text="NO"
            v-model="scope.row.CancelFlagValue"
          >
          </el-switch>
        </template> -->
        <template #default="scope">
          <el-checkbox
            :disabled="scope.row.disabled"
            v-model="scope.row.CancelFlagValue"
            @change="changeSwitch(scope.row)"
          ></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in tableTitle"
        :key="index"
        :prop="item.prop"
        :label="item.name"
        :align="item.align ? item.align : 'center'"
        :min-width="item.percent"
        :sortable="item.sortable ? 'custom' : true"
        :show-overflow-tooltip="true"
        :filter-placement="'bottom-end'"
      >
        <template #default="scope" v-if="item.name == '差异'">
          <span :class="scope.row.index7 > 0 ? 'delete-text-color' : null">{{
            scope.row.index7
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="locationCode"
        label="收货数量"
        width="150"
        align="center"
      >
        <template #default="scope">
          <div id="input-InnerStyle">
            <el-input
              v-model="scope.row.RecNumber"
              @input="changeNum(scope.row)"
              size="mini"
              onkeyup="this.value=this.value.match(/^\d{1,10}(\.\d{0,4})?/)?this.value.match(/^\d{1,10}(\.\d{0,4})?/)[0]:''"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="locationCode"
        label="库位编码"
        width="170"
        align="center"
      >
        <template #default="scope">
          <div id="input-InnerStyle" v-if="scope.row.LocationEditFlag">
            <el-input
              type="text"
              v-model="scope.row.LocationEditNo"
              size="small"
              @blur="
                changeScopeRowValue(
                  scope.$index,
                  scope.row,
                  'compare',
                  'receipt'
                )
              "
            />
          </div>
          <span v-else>{{ scope.row.LocationEditNo }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="InventoryReportDetail"
        label="澄清数量"
        width="110"
        align="center"
      >
        <template #default="scope">
          <div id="input-InnerStyle">
            <el-input
              v-model="scope.row.clarifyNum"
              @input="changeNum(scope.row)"
              size="mini"
              onkeyup="this.value=this.value.match(/^\d{1,10}(\.\d{0,4})?/)?this.value.match(/^\d{1,10}(\.\d{0,4})?/)[0]:''"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="isPhotos"
        label="图片证明"
        width="88"
        align="center"
      >
        <!-- <template>
          <el-image
            width="40"
            height="40"
            src="require('@../assets/img/img.jpg')"
          ></el-image>
        </template> -->
        <template #default="scope">
          <!-- {{scope.row.FileUploadFlag}} -->
          <div v-if="scope.row.FileUploadFlag !== 1">
            <span
              style="color: blue"
              class="hoverDownFile"
              @click="downLoadFile(scope.row, 'isPhotos')"
              >{{ scope.row.AttachName }}</span
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="orderNumber"
        label="订购数量"
        width="110"
        align="center"
      >
        <template #default="scope">
          <div id="input-InnerStyle">
            <el-input
              v-model="scope.row.Number"
              @input="changeNum(scope.row)"
              size="mini"
              onkeyup="this.value=this.value.match(/^\d{1,10}(\.\d{0,4})?/)?this.value.match(/^\d{1,10}(\.\d{0,4})?/)[0]:''"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="updatePickingPickNumber"
        label="领用数量"
        width="110"
        align="center"
      >
        <template #default="scope">
          <div id="input-InnerStyle">
            <el-input
              v-model="scope.row.InventoryNumber"
              @input="changeNum(scope.row)"
              size="mini"
              onkeyup="this.value=this.value.match(/^\d{1,10}(\.\d{0,4})?/)?this.value.match(/^\d{1,10}(\.\d{0,4})?/)[0]:''"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="deliveryNumber" label="发货数量" width="110">
        <template #default="scope">
          <div id="input-InnerStyle">
            <el-input
              v-model="scope.row.ShipQuantity"
              @input="changeNum(scope.row)"
              size="mini"
              align="center"
              style="width: 68px; height: 28px"
              onkeyup="this.value=this.value.match(/^\d{1,10}(\.\d{0,4})?/)?this.value.match(/^\d{1,10}(\.\d{0,4})?/)[0]:''"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="modifyMaterialreturnNumber"
        label="退料数量"
        width="110"
        align="right"
      >
        <template #default="scope">
          <div id="input-InnerStyle">
            <el-input
              v-model="scope.row.ReturnNumber"
              @input="changeNum(scope.row)"
              size="mini"
              onkeyup="this.value=this.value.match(/^\d{1,10}(\.\d{0,4})?/)?this.value.match(/^\d{1,10}(\.\d{0,4})?/)[0]:''"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="NotAvailableNumber"
        label="申请数量"
        width="110"
        height="28px"
        align="center"
      >
        <template #default="scope">
          <div id="input-InnerStyle">
            <el-input
              v-model="scope.row.ApplyQuantity"
              @input="changeNum(scope.row)"
              size="mini"
              onkeyup="this.value=this.value.match(/^\d{1,10}(\.\d{0,4})?/)?this.value.match(/^\d{1,10}(\.\d{0,4})?/)[0]:''"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="SalesPrice === '01'"
        label="单价"
        width="100"
        height="28px"
        align="center"
      >
        <template #default="scope">
          <div id="input-price">
            <el-input
              id="price"
              v-model="scope.row.SalesPrice"
              @input="SalsePrice(scope.row)"
              @focus="changeScopeRowNumber(scope.row, '', 'SalesPrice')"
              @blur="changeScopeRowNumber(scope.row, 0, 'SalesPrice')"
              size="mini"
              style="width: 74px"
              onkeyup="this.value=this.value.match(/\d+(\.\d{0,4})?/)?this.value.match(/\d+(\.\d{0,4})?/)[0]:''"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="creatematerialreturnReason"
        label="退料原因"
        width="145"
        align="left"
      >
        <template #default="scope">
          <div id="input-InnerStyle">
            <el-input v-model="scope.row.ReturnReason" size="small"> </el-input>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="AlreadyShipNumber"
        label="送货数量"
        width="90"
        align="right"
      >
        <template #default="scope">
          <span>{{ scope.row.AlreadyShipNumber }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="AlreadySignNumber"
        label="签收数量"
        width="90"
        align="right"
      >
        <template #default="scope">
          <span>{{ scope.row.AlreadySignNumber }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="AlreadyShipNum"
        label="送货数量"
        width="90"
        align="right"
      >
        <template #default="scope">
          <span>{{ scope.row.AlreadyShipNum }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="AlreadyRecNum"
        label="签收数量"
        width="90"
        align="right"
      >
        <template #default="scope">
          <span>{{ scope.row.AlreadyRecNum }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="PickNumber"
        label="领用数量"
        width="110"
        align="center"
      >
        <template #default="scope">
          <div id="input-InnerStyle">
            <el-input
              v-model="scope.row.Number"
              @input="changeNum(scope.row)"
              size="mini"
              onkeyup="this.value=this.value.match(/^\d{1,10}(\.\d{0,4})?/)?this.value.match(/^\d{1,10}(\.\d{0,4})?/)[0]:''"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="PickType"
        label="领用分类"
        width="155"
        align="center"
      >
        <template #default="scope">
          <div class="selectjs" id="pickType">
            <el-autocomplete
              size="mini"
              class="inline-input"
              @change="pickSelect"
              v-model="scope.row.pickType"
              :onFocus="onFocus"
              :onBlur="onBlur"
              v-bind:disabled="disabled"
              :fetch-suggestions="querySearch"
              value-key="label"
              placement="top-start"
              placeholder="请选择领用分类"
              @select="pickSelect(scope.row)"
            >
              <template #suffix>
                <i
                  class="el-icon-edit el-icon-arrow-down"
                  @click="handleIconClick"
                >
                </i>
              </template>
            </el-autocomplete>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        v-if="updatePickingPickNumber || PickNumber"
        label="备注"
        width="160"
        align="center"
      >
        <template #default="scope">
          <div>
            <el-input
              spellcheck="false"
              type="text"
              v-model="scope.row.Remarks"
              size="mini"
            />
          </div>
        </template>
      </el-table-column>
      <!-- 领料申请模块用 -->
      <el-table-column
        v-if="PickNumber || updatePickingIsReturn"
        label="是否以旧换新"
        width="120"
        align="center"
      >
        <template #default="scope">
          <div>
            <el-checkbox v-model="scope.row.IsReturn">
              <span v-if="scope.row.IsReturn">是</span>
              <span v-if="!scope.row.IsReturn">否</span>
            </el-checkbox>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="MaterialType"
        label="物料状态"
        width="150"
        align="center"
      >
        <template #default="scope">
          <div class="selectjs">
            <el-autocomplete
              size="mini"
              class="inline-input"
              @change="pickSelect"
              :onFocus="onFocus"
              :onBlur="onBlur"
              v-model="scope.row.materialType"
              v-bind:disabled="disabled"
              :fetch-suggestions="MaterSearch"
              value-key="label"
              placeholder="请输入物料状态"
              style="width: 130px"
              width="130"
              @select="pickSelect(scope.row)"
              placement="top-start"
            >
              <template #suffix>
                <i
                  class="el-icon-edit el-icon-arrow-down"
                  @click="handleIconClick"
                >
                </i>
              </template>
            </el-autocomplete>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="orderNumber"
        label="交货日期"
        width="150"
        align="center"
      >
        <template #default="scope">
          <div id="input-InnerStyle">
            <el-date-picker
              v-model="scope.row.DeliveryDate"
              align="right"
              type="date"
              size="small"
              style="width: 125px"
              @change="deliveryData(scope.row)"
              @blur="changeScopeRowNumber(scope.row, 0, 'createOrder')"
            >
            </el-date-picker>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="materialRemark"
        label="说明"
        width="160"
        align="center"
      >
        <template #default="scope">
          <div>
            <el-input
              spellcheck="false"
              type="text"
              v-model="scope.row.Remarks"
              size="mini"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="locationCode"
        label="操作"
        width="80"
        align="center"
      >
        <template #default="scope">
          <!-- 收货单--收货页面的拆分按钮 -->
          <el-button
            @click="split(scope.$index)"
            plain
            type="primary"
            size="small"
            >拆分</el-button
          >
        </template>
      </el-table-column>
      <!-- 领料单待发货列表：发货和关闭按钮 -->
      <el-table-column
        v-if="deliverGoods && isAutoShip !== 1"
        label="操作"
        width="150"
        align="center"
      >
        <template #default="scope">
          <el-button
            @click.stop="toDeliverGoods(scope.row)"
            type="primary"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find(
                (item) => item.operateId === 'deliver'
              ).isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find(
                (item) => item.operateId === "deliver"
              ).operateName
            }}
          </el-button>
          <el-button
            @click.stop="close(scope.row)"
            type="danger"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === 'close')
                .isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "close")
                .operateName
            }}
          </el-button>
        </template>
      </el-table-column>
      <!-- 领料单待发货列表：只有关闭按钮 -->
      <el-table-column
        v-if="deliverGoods && isAutoShip == 1"
        label="操作"
        width="80"
        align="center"
      >
        <template #default="scope">
          <el-button
            @click.stop="close(scope.row)"
            type="danger"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === 'close')
                .isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "close")
                .operateName
            }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        v-if="awaitDeliverGoods"
        label="操作"
        width="80"
        align="center"
      >
        <template #default="scope">
          <el-button
            @click.stop="toAwaitDeliverGoods(scope.row)"
            type="primary"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find(
                (item) => item.operateId === 'deliver'
              ).isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find(
                (item) => item.operateId === "deliver"
              ).operateName
            }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column v-if="approval" label="操作" width="80" align="center">
        <template #default="scope">
          <el-button
            v-show="scope.row.approvalOperation === 'edit'"
            @click.stop="approvalEdit(scope.row)"
            type="primary"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find(
                (item) => item.operateId === 'confirm'
              ).isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find(
                (item) => item.operateId === "confirm"
              ).operateName
            }}
          </el-button>
          <el-button
            v-show="scope.row.approvalOperation === 'inventory'"
            @click.stop="approvalEdit(scope.row)"
            type="primary"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find(
                (item) => item.operateId === 'inventory'
              ).isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find(
                (item) => item.operateId === "inventory"
              ).operateName
            }}
          </el-button>
          <el-button
            v-show="scope.row.approvalOperation === 'submit'"
            @click.stop="approvalEdit(scope.row)"
            type="primary"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find(
                (item) => item.operateId === 'submit'
              ).isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find(
                (item) => item.operateId === "submit"
              ).operateName
            }}
          </el-button>
          <el-button
            v-show="
              scope.row.approvalOperation !== 'edit' &&
              scope.row.approvalOperation !== 'inventory' &&
              scope.row.approvalOperation !== 'submit'
            "
            @click.stop="editData(scope.$index, tableData, tableRouter)"
            type="primary"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find(
                (item) => item.operateId === 'approve'
              ).isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find(
                (item) => item.operateId === "approve"
              ).operateName
            }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        v-if="editAndRemove"
        label="操作"
        width="150"
        align="center"
      >
        <template #default="scope">
          <!-- 盘点日期设定列表,该页面未疏通，未设置动态权限 -->
          <el-button
            @click.stop="changeSth(scope.$index, tableData)"
            type="text"
            size="small"
            >修改</el-button
          >
          <el-button
            @click.stop="openDialog(scope.$index, tableData, ' shanchu')"
            type="text"
            size="small"
            class="delete-text-color"
            >删除</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        v-if="materalList"
        label="操作"
        width="80px"
        align="center"
      >
        <template #default="scope">
          <el-button
            @click.stop="editData(scope.$index, tableData, tableRouter)"
            type="primary"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === 'view')
                .isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "view")
                .operateName
            }}
          </el-button>
          <!-- <el-button
            @click.stop="changeSth(scope.$index, tableData)"
            type="success"
            size="small"
            plain
            :disabled="
              operateInfo &&
                operateInfo.operateInfo.find(
                  (item) => item.operateId === 'edit'
                ).isDisable
            "
          >
            {{
              operateInfo &&
                operateInfo.operateInfo.find(
                  (item) => item.operateId === "edit"
                ).operateName
            }}
          </el-button>-->
        </template>
      </el-table-column>
      <el-table-column
        v-if="InventoryReportList"
        label="操作"
        width="300px"
        align="center"
      >
        <template #default="scope">
          <el-button
            @click.stop="editData(scope.$index, tableData, tableRouter, 'view')"
            type="primary"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === 'view')
                .isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "view")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="editData(scope.$index, tableData, tableRouter, 'edit')"
            type="success"
            size="small"
            plain
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find(
                  (item) => item.operateId === 'edit'
                ).isDisable) ||
              scope.row.Status !== '02'
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "edit")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="
              editData(
                scope.$index,
                tableData,
                tableRouter,
                'differenceConfirm'
              )
            "
            type="primary"
            size="small"
            plain
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find(
                  (item) => item.operateId === 'differenceConfirm'
                ).isDisable) ||
              scope.row.Status !== '03'
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find(
                (item) => item.operateId === "differenceConfirm"
              ).operateName
            }}
          </el-button>
          <el-button
            @click.stop="
              editData(scope.$index, tableData, tableRouter, 'moveAccount')
            "
            type="primary"
            size="small"
            plain
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find(
                  (item) => item.operateId === 'moveAccount'
                ).isDisable) ||
              scope.row.Status !== '04'
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find(
                (item) => item.operateId === "moveAccount"
              ).operateName
            }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        v-if="unavailablematerialList"
        label="操作"
        width="210px"
        align="center"
      >
        <template #default="scope">
          <el-button
            @click.stop="editData(scope.$index, tableData, tableRouter, 'view')"
            type="primary"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === 'view')
                .isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "view")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="changeSth(scope.$index, tableData)"
            type="success"
            size="small"
            plain
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find(
                  (item) => item.operateId === 'edit'
                ).isDisable) ||
              scope.row.Status !== '01' ||
              scope.row.CreatedBy.toLowerCase() !== CreatedBy
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "edit")
                .operateName
            }}
          </el-button>
          <el-button
            @click="deleteData(scope.$index, tableData, 'shanchu')"
            type="danger"
            size="small"
            plain
            class="delete-text-color"
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find((item) => item.operateId === 'del')
                  .isDisable) ||
              scope.row.CreatedBy.toLowerCase() !== CreatedBy
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "del")
                .operateName
            }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        v-if="returngoodInfo"
        label="操作"
        width="210px"
        align="center"
      >
        <template #default="scope">
          <el-button
            @click.stop="editData(scope.$index, tableData, tableRouter)"
            type="primary"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === 'view')
                .isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "view")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="changeSth(scope.$index, tableData)"
            type="success"
            size="small"
            plain
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find(
                  (item) => item.operateId === 'edit'
                ).isDisable) ||
              scope.row.Status !== '01' ||
              scope.row.CreatedBy.toLowerCase() !== CreatedBy
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "edit")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="retrievalClick(scope.row)"
            type="primary"
            size="small"
            plain
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find(
                  (item) => item.operateId === 'retrieval'
                ).isDisable) ||
              scope.row.Status !== '02'
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find(
                (item) => item.operateId === "retrieval"
              ).operateName
            }}
          </el-button>
        </template>
      </el-table-column>
      <!-- 调拨单列表 -->
      <el-table-column
        v-if="detailAndUpdata"
        label="操作"
        :width="allocationDetail ? '210px' : '145px'"
        align="center"
      >
        <template #default="scope">
          <el-button
            @click.stop="editData(scope.$index, tableData, tableRouter)"
            type="primary"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === 'view')
                .isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "view")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="changeSth(scope.$index, tableData)"
            type="success"
            size="small"
            plain
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find(
                  (item) => item.operateId === 'edit'
                ).isDisable) ||
              scope.row.Status !== '01' ||
              scope.row.CreatedBy.toLowerCase() !== CreatedBy
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "edit")
                .operateName
            }}
          </el-button>
          <el-button
            v-if="allocationDetail"
            @click.stop="deleteData(scope.$index, tableData, 'shanchu')"
            type="danger"
            size="small"
            plain
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find((item) => item.operateId === 'del')
                  .isDisable) ||
              scope.row.Status !== '01' ||
              scope.row.CreatedBy.toLowerCase() !== CreatedBy
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "del")
                .operateName
            }}
          </el-button>
        </template>
      </el-table-column>
      <!-- 领料单发货列表：修改和删除按钮不隐藏 -->
      <el-table-column
        v-if="detailAndUpdataAndDeliverOrReceive"
        label="操作"
        width="420px"
        align="center"
      >
        <template #default="scope">
          <el-button
            @click.stop="Printtranslation(scope.$index, tableData, tableRouter)"
            type="primary"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === 'print')
                .isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "print")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="editData(scope.$index, tableData, tableRouter)"
            type="primary"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === 'view')
                .isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "view")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="changeSth(scope.$index, tableData)"
            type="success"
            size="small"
            plain
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find(
                  (item) => item.operateId === 'edit'
                ).isDisable) ||
              scope.row.Status !== '01'
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "edit")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="deliverClick(scope.row)"
            type="primary"
            size="small"
            plain
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find(
                  (item) => item.operateId === 'deliver'
                ).isDisable) ||
              scope.row.Status !== '01'
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find(
                (item) => item.operateId === "deliver"
              ).operateName
            }}
          </el-button>
          <el-button
            @click.stop="receiveClick(scope.row)"
            type="primary"
            size="small"
            plain
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find(
                  (item) => item.operateId === 'receive'
                ).isDisable) ||
              scope.row.Status !== '02'
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find(
                (item) => item.operateId === "receive"
              ).operateName
            }}
          </el-button>
          <el-button
            @click.stop="deleteData(scope.$index, tableData, 'shanchu')"
            type="danger"
            size="small"
            plain
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find((item) => item.operateId === 'del')
                  .isDisable) ||
              scope.row.Status !== '01'
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "del")
                .operateName
            }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        v-if="showPaymentRecord"
        label="操作"
        width="210px"
        align="center"
      >
        <template #default="scope">
          <el-button
            @click.stop="editData(scope.$index, tableData, tableRouter)"
            type="primary"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === 'view')
                .isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "view")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="changeSth(scope.$index, tableData)"
            type="success"
            size="small"
            plain
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find(
                  (item) => item.operateId === 'edit'
                ).isDisable) ||
              scope.row.StatusName !== '待提交'
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "edit")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="deleteData(scope.$index, tableData, 'shanchu')"
            type="danger"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === 'del')
                .isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "del")
                .operateName
            }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        v-if="showCostCenter"
        label="操作"
        width="225px"
        align="center"
      >
        <template #default="scope">
          <el-button
            @click.stop="changeSth(scope.$index, tableData)"
            type="success"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === 'edit')
                .isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "edit")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="enableSth(scope.$index, tableData)"
            type="primary"
            size="small"
            plain
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find(
                  (item) => item.operateId === 'enable'
                ).isDisable) ||
              scope.row.DiscardFlag === '0'
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find(
                (item) => item.operateId === "enable"
              ).operateName
            }}
          </el-button>
          <el-button
            @click.stop="disableSth(scope.$index, tableData)"
            type="danger"
            size="small"
            plain
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find(
                  (item) => item.operateId === 'disable'
                ).isDisable) ||
              scope.row.DiscardFlag === '1'
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find(
                (item) => item.operateId === "disable"
              ).operateName
            }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        v-if="rfidList"
        label="操作"
        width="225px"
        align="center"
      >
        <template #default="scope">
          <el-button
            @click.stop="changeSth(scope.$index, tableData)"
            type="success"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find(
                (item) => item.operateId === 'update'
              ).isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find(
                (item) => item.operateId === "update"
              ).operateName
            }}
          </el-button>
          <el-button
            @click.stop="enableSth(scope.$index, tableData)"
            type="primary"
            size="small"
            plain
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find(
                  (item) => item.operateId === 'undisable'
                ).isDisable) ||
              scope.row.DiscardFlag === '0'
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find(
                (item) => item.operateId === "undisable"
              ).operateName
            }}
          </el-button>
          <el-button
            @click.stop="disableSth(scope.$index, tableData)"
            type="danger"
            size="small"
            plain
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find(
                  (item) => item.operateId === 'disable'
                ).isDisable) ||
              scope.row.DiscardFlag === '1'
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find(
                (item) => item.operateId === "disable"
              ).operateName
            }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        v-if="detailAndUpdataMaterialRequest"
        label="操作"
        width="212px"
        align="center"
      >
        <template #default="scope">
          <el-button
            @click.stop="editData(scope.$index, tableData, tableRouter)"
            type="primary"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === 'view')
                .isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "view")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="changeSth(scope.$index, tableData)"
            type="success"
            size="small"
            plain
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find(
                  (item) => item.operateId === 'edit'
                ).isDisable) ||
              scope.row.Status !== '01' ||
              scope.row.CreatedBy.toLowerCase() !== CreatedBy
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "edit")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="deleteData(scope.$index, tableData, 'shanchu')"
            type="danger"
            size="small"
            plain
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find((item) => item.operateId === 'del')
                  .isDisable) ||
              scope.row.Status !== '01' ||
              scope.row.CreatedBy.toLowerCase() !== CreatedBy
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "del")
                .operateName
            }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        v-if="showReceiverList"
        label="操作"
        width="150px"
        align="center"
      >
        <template #default="scope">
          <el-button
            @click.stop="toReceiverDetail(scope.$index, tableData, 'submit')"
            type="success"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find(
                (item) => item.operateId === 'receiving'
              ).isDisable
            "
            v-if="scope.row.Status === '02'"
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find(
                (item) => item.operateId === "receiving"
              ).operateName
            }}
          </el-button>
          <el-button
            @click.stop="toReceiverDetail(scope.$index, tableData, 'detail')"
            type="primary"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === 'view')
                .isDisable
            "
            v-else
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "view")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="Printtranslation(scope.$index, tableData, tableRouter)"
            type="primary"
            size="small"
            plain
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find(
                  (item) => item.operateId === 'print'
                ).isDisable) ||
              scope.row.RecType !== '01'
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "print")
                .operateName
            }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        v-if="deleteInformation"
        label="操作"
        width="80"
        align="center"
      >
        <template #default="scope">
          <!--子页面删除按钮-->
          <el-button
            @click.stop="openDialog(scope.$index, tableData, 'shanchu')"
            type="danger"
            size="small"
            plain
            >删除</el-button
          >
        </template>
      </el-table-column>
      <el-table-column v-if="detail" label="操作" width="80px" align="center">
        <template #default="scope">
          <el-button
            @click.stop="editData(scope.$index, tableData, tableRouter)"
            type="primary"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === 'view')
                .isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "view")
                .operateName
            }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        v-if="SupplierDetail"
        label="操作"
        width="225px"
        align="center"
      >
        <template #default="scope">
          <el-button
            @click.stop="editData(scope.$index, tableData, tableRouter)"
            type="primary"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === 'view')
                .isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "view")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="changeSth(scope.$index, tableData)"
            type="success"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === 'edit')
                .isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "edit")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="viewFill(scope.$index, tableData)"
            type="primary"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === 'fill')
                .isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "fill")
                .operateName
            }}
          </el-button>
        </template>
      </el-table-column>
      <!-- 以旧换新列表  -->
      <el-table-column
        v-if="srreturnlist"
        label="操作"
        width="110px"
        align="center"
      >
        <template #default="scope">
          <el-button
            @click.stop="changeSth(scope.$index, tableData)"
            type="success"
            size="small"
            plain
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find(
                  (item) => item.operateId === 'return'
                ).isDisable) ||
              (scope.row.Status !== '01' && scope.row.Status !== '03')
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find(
                (item) => item.operateId === "return"
              ).operateName
            }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        v-if="accountAdjustment"
        label="操作"
        width="80px"
        align="center"
      >
        <template #default>
          <!-- 调账列表,该页面暂时不要了，未设置动态权限 -->
          <el-button type="text" size="small">调账</el-button>
        </template>
      </el-table-column>
      <el-table-column
        v-if="relocation"
        label="操作"
        width="80px"
        align="center"
      >
        <template #default="scope">
          <!-- 同库房移库,该页面暂时不要了，未设置动态权限 -->
          <el-button
            type="text"
            size="small"
            @click="openDialog(scope.$index, tableData, 'yiku')"
            >移库
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        v-if="accountList"
        label="操作"
        align="center"
        width="210px"
      >
        <template #default="scope">
          <el-button
            @click.stop="changeSth(scope.$index, tableData)"
            type="success"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === 'edit')
                .isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "edit")
                .operateName
            }}
          </el-button>
          <el-button
            type="primary"
            size="small"
            plain
            @click="openDialog(scope.$index, tableData, 'qiyong')"
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find(
                (item) => item.operateId === 'unenabled'
              ).isDisable
                ? true
                : tableData[scope.$index].DiscardFlag === '1'
                ? false
                : true
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find(
                (item) => item.operateId === "unenabled"
              ).operateName
            }}
          </el-button>
          <el-button
            type="danger"
            size="small"
            plain
            @click="openDialog(scope.$index, tableData, 'jinyong')"
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find(
                (item) => item.operateId === 'enabled'
              ).isDisable
                ? true
                : tableData[scope.$index].DiscardFlag === '0'
                ? false
                : true
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find(
                (item) => item.operateId === "enabled"
              ).operateName
            }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column v-if="roleList" label="操作" width="300" align="center">
        <template #default="scope">
          <el-button
            @click.stop="checkRoot(scope.$index, tableData, false)"
            type="primary"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === 'view')
                .isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "view")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="checkRoot(scope.$index, tableData, true)"
            type="primary"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === 'role')
                .isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "role")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="changeSth(scope.$index, tableData)"
            type="success"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === 'edit')
                .isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "edit")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="deleteData(scope.$index, tableData, 'shanchu')"
            type="danger"
            size="small"
            plain
            class="delete-text-color"
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === 'del')
                .isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "del")
                .operateName
            }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        v-if="locationList"
        align="center"
        label="操作"
        width="145px"
      >
        <template #default="scope">
          <el-button
            @click.stop="changeSth(scope.$index, tableData)"
            type="success"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === 'edit')
                .isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "edit")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="deleteData(scope.$index, tableData, 'shanchu')"
            type="danger"
            size="small"
            plain
            class="delete-text-color"
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === 'del')
                .isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "del")
                .operateName
            }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        v-if="updateMaterial"
        align="center"
        label="操作"
        width="80px"
      >
        <template #default="scope">
          <el-button
            @click.stop="changeSth(scope.$index, tableData)"
            type="success"
            size="small"
            plain
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find(
                  (item) => item.operateId === 'update'
                ).isDisable) ||
              scope.row.CreatedBy.toLowerCase() !== CreatedBy
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find(
                (item) => item.operateId === "update"
              ).operateName
            }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        v-if="lnventoryList"
        align="center"
        label="操作"
        width="80px"
      >
        <template #default="scope">
          <!-- <el-button
            @click="split(scope.$index)"
            plain
            type="primary"
            size="small"
          >移库</el-button> -->
          <el-button
            @click="split(scope.$index)"
            type="primary"
            size="small"
            plain
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find(
                  (item) => item.operateId === 'move'
                ).isDisable) ||
              scope.row.Business_Unit !== scope.row.loginBusinessUnit
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "move")
                .operateName
            }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        v-if="orderList"
        align="center"
        label="操作"
        width="280px"
      >
        <template #default="scope">
          <el-button
            @click.stop="editData(scope.$index, tableData, tableRouter)"
            type="primary"
            plain
            size="small"
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === 'view')
                .isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "view")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="editData(scope.$index, tableData, tableRouter, 'edit')"
            type="success"
            plain
            size="small"
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find(
                  (item) => item.operateId === 'edit'
                ).isDisable) ||
              scope.row.CreatedBy.toLowerCase() !== CreatedBy
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "edit")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="close(scope.$index)"
            type="danger"
            size="small"
            plain
            class="delete-text-color"
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find((item) => item.operateId === 'del')
                  .isDisable) ||
              scope.row.CreatedBy.toLowerCase() !== CreatedBy
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "del")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="deleteData(scope.$index, tableData, 'shanchu')"
            type="danger"
            size="small"
            plain
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find(
                  (item) => item.operateId === 'back'
                ).isDisable) ||
              scope.row.CreatedBy.toLowerCase() !== CreatedBy
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "back")
                .operateName
            }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        v-if="MaterialDailyInventoryList"
        align="center"
        label="操作"
        width="80px"
      >
        <template #default="scope">
          <el-button
            @click.stop="editData(scope.$index, tableData, tableRouter)"
            type="primary"
            plain
            size="small"
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === 'view')
                .isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "view")
                .operateName
            }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        v-if="toOrderList"
        align="center"
        label="操作"
        width="420px"
      >
        <template #default="scope">
          <el-button
            @click.stop="split(scope.$index)"
            type="primary"
            plain
            size="small"
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find(
                  (item) => item.operateId === 'split'
                ).isDisable) ||
              !(scope.row.Status === '03' || scope.row.Status === '01') ||
              scope.row.CreatedBy.toLowerCase() !== CreatedBy
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "split")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="order(scope.$index)"
            type="primary"
            plain
            size="small"
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find((item) => item.operateId === 'add')
                  .isDisable) ||
              scope.row.Status !== '03' ||
              scope.row.CreatedBy.toLowerCase() !== CreatedBy
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "add")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="editData(scope.$index, tableData, tableRouter)"
            type="primary"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === 'view')
                .isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "view")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="changeSth(scope.$index, tableData)"
            type="success"
            plain
            size="small"
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find(
                  (item) => item.operateId === 'edit'
                ).isDisable) ||
              scope.row.CreatedBy.toLowerCase() !== CreatedBy
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "edit")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="deleteData(scope.$index, tableData, 'shanchu')"
            type="danger"
            size="small"
            plain
            class="delete-text-color"
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find((item) => item.operateId === 'del')
                  .isDisable) ||
              scope.row.CreatedBy.toLowerCase() !== CreatedBy
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "del")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="deleteData(scope.$index, tableData, 'back')"
            type="danger"
            size="small"
            plain
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find(
                  (item) => item.operateId === 'back'
                ).isDisable) ||
              scope.row.CreatedBy.toLowerCase() !== CreatedBy
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "back")
                .operateName
            }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        v-if="noticeList"
        align="center"
        label="操作"
        width="210px"
      >
        <template #default="scope">
          <el-button
            @click.stop="editData(scope.$index, tableData, tableRouter)"
            type="primary"
            plain
            size="small"
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === 'view')
                .isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "view")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="changeSth(scope.$index, tableData)"
            type="success"
            plain
            size="small"
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find(
                  (item) => item.operateId === 'edit'
                ).isDisable) ||
              scope.row.Status !== '01'
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "edit")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="deleteData(scope.$index, tableData, 'shanchu')"
            type="danger"
            plain
            size="small"
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === 'del')
                .isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "del")
                .operateName
            }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        v-if="detailAndUpdataAndDeliver"
        label="操作"
        width="350px"
        align="center"
      >
        <template #default="scope">
          <!-- 打印按钮 -->
          <el-button
            @click.stop="Printtranslation(scope.$index, tableData, tableRouter)"
            type="primary"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === 'print')
                .isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "print")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="editData(scope.$index, tableData, tableRouter)"
            type="primary"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === 'view')
                .isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "view")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="changeSth(scope.$index, tableData)"
            type="success"
            size="small"
            plain
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find(
                  (item) => item.operateId === 'edit'
                ).isDisable) ||
              scope.row.Status !== '01'
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "edit")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="deliverClick(scope.row)"
            type="primary"
            size="small"
            plain
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find(
                  (item) => item.operateId === 'deliver'
                ).isDisable) ||
              scope.row.Status !== '01'
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find(
                (item) => item.operateId === "deliver"
              ).operateName
            }}
          </el-button>
          <el-button
            @click.stop="deleteData(scope.$index, tableData, 'shanchu')"
            type="danger"
            size="small"
            plain
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find((item) => item.operateId === 'del')
                  .isDisable) ||
              scope.row.Status !== '01'
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "del")
                .operateName
            }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        v-if="retrieval"
        label="操作"
        width="80px"
        align="center"
      >
        <template #default="scope">
          <el-button
            @click.stop="retrievalClick(scope.row)"
            type="primary"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find(
                (item) => item.operateId === 'retrieval'
              ).isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find(
                (item) => item.operateId === "retrieval"
              ).operateName
            }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        v-if="detailAndEditAndDelAndRetrieval"
        label="操作"
        width="300px"
        align="center"
      >
        <template #default="scope">
          <el-button
            @click.stop="editData(scope.$index, tableData, tableRouter, 'view')"
            type="primary"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === 'view')
                .isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "view")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="changeSth(scope.$index, tableData)"
            type="success"
            size="small"
            plain
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find(
                  (item) => item.operateId === 'edit'
                ).isDisable) ||
              scope.row.Status !== '01' ||
              scope.row.CreatedBy.toLowerCase() !== CreatedBy
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "edit")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="retrievalClick(scope.row)"
            type="primary"
            size="small"
            plain
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find(
                  (item) => item.operateId === 'retrieval'
                ).isDisable) ||
              scope.row.Status !== '03'
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find(
                (item) => item.operateId === "retrieval"
              ).operateName
            }}
          </el-button>
          <el-button
            @click="deleteData(scope.$index, tableData, 'shanchu')"
            type="danger"
            size="small"
            plain
            class="delete-text-color"
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find((item) => item.operateId === 'del')
                  .isDisable) ||
              scope.row.CreatedBy.toLowerCase() !== CreatedBy
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "del")
                .operateName
            }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        v-if="clearAndAdmit && !againPan"
        label="是否复盘"
        width="80px"
        align="center"
      >
        <template #default="scope">
          <!-- 盘点报告子页面按钮 -->
          <div v-if="clearAndAdmit === 'view'">
            {{ scope.row.ReInventoryCheckFlag === 0 ? "否" : "是" }}
          </div>
          <div v-else>
            <div
              v-if="
                (scope.row.CheckStatusName === '待澄清' &&
                  scope.row.ReInventoryCheckFlag !== 1) ||
                scope.row.isFP
              "
            >
              <el-checkbox
                :checked="scope.row.isFP"
                v-model="scope.row.isFP"
                @change="changeIsFP(scope.row)"
                >是</el-checkbox
              >
            </div>
            <div v-else>
              {{ scope.row.ReInventoryCheckFlag === 0 ? "否" : "是" }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="clearAndAdmit && !againPan"
        label="复盘数量"
        width="110px"
        align="right"
      >
        <template #default="scope">
          {{ scope.row.ReplayQuantity }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="clearAndAdmit"
        label="澄清数量"
        width="110px"
        align="right"
      >
        <template #default="scope">
          <div v-if="clearAndAdmit === 'view'">
            {{ scope.row.ClarifyQuantity }}
          </div>
          <div v-else>
            <div
              v-if="
                (scope.row.CheckStatusName === '待澄清' &&
                  scope.row.ReInventoryCheckFlag === 1 &&
                  scope.row.InventoryQuantity !== scope.row.ReplayQuantity) ||
                (scope.row.CheckStatusName === '待澄清' &&
                  scope.row.ReInventoryCheckFlag !== 1 &&
                  scope.row.InventoryQuantity !== scope.row.CheckQuantity)
              "
            >
              <el-input
                v-if="!scope.row.isFP"
                v-model="scope.row.ClarifyQuantity"
                size="mini"
                @input="changeNum(scope.row)"
                onkeyup="this.value=this.value.match(/^\d{1,10}(\.\d{0,4})?/)?this.value.match(/^\d{1,10}(\.\d{0,4})?/)[0]:''"
              />
            </div>
            <div v-else>
              {{ scope.row.ClarifyQuantity }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="clearAndAdmit"
        label="澄清理由"
        width="300px"
        align="left"
      >
        <template #default="scope">
          <div v-if="clearAndAdmit === 'view'">
            {{ scope.row.ClarifyReason }}
          </div>
          <div v-else>
            <div
              v-if="
                (scope.row.CheckStatusName === '待澄清' &&
                  scope.row.ReInventoryCheckFlag === 1 &&
                  scope.row.InventoryQuantity !== scope.row.ReplayQuantity) ||
                (scope.row.CheckStatusName === '待澄清' &&
                  scope.row.ReInventoryCheckFlag !== 1 &&
                  scope.row.InventoryQuantity !== scope.row.CheckQuantity)
              "
            >
              <el-input
                v-if="!scope.row.isFP"
                v-model="scope.row.ClarifyReason"
                type="text"
                size="mini"
              />
            </div>
            <div v-else>
              {{ scope.row.ClarifyReason }}
            </div>
            <!-- <div v-if="
                scope.row.CheckStatusName !== '待澄清' ||
                  scope.row.ReInventoryCheckFlag === 1
              ">
              {{ scope.row.ClarifyReason }}
            </div>
            <div v-else>
              <el-input
                v-if="!scope.row.isFP"
                v-model="scope.row.ClarifyReason"
                type="text"
                size="mini"
              />
            </div> -->
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="clearAndAdmit"
        label="澄清附件"
        min-width="150px"
        align="center"
      >
        <template #default="scope">
          <div v-if="clearAndAdmit === 'view'">
            <span
              style="color: blue"
              class="hoverDownFile"
              @click="downLoadFile(scope.row, 'clearAndAdmit')"
              >{{ scope.row.AttachName }}</span
            >
          </div>
          <div v-else>
            <!-- <div
              v-if="scope.row.CheckStatusName!=='待澄清' || scope.row.ReInventoryCheckFlag===1"
            >
              <span
                style="color:blue"
                class="hoverDownFile"
                @click="downLoadFile(scope.row,'clearAndAdmit')"
              >{{scope.row.AttachName}}</span>
            </div> -->
            <div v-if="scope.row.isFP">
              <span
                style="color: blue"
                class="hoverDownFile"
                @click="downLoadFile(scope.row, 'clearAndAdmit')"
                >{{ scope.row.AttachName }}</span
              >
            </div>
            <div
              v-else-if="
                (scope.row.CheckStatusName === '待澄清' &&
                  scope.row.ReInventoryCheckFlag === 1 &&
                  scope.row.InventoryQuantity !== scope.row.ReplayQuantity) ||
                (scope.row.CheckStatusName === '待澄清' &&
                  scope.row.ReInventoryCheckFlag !== 1 &&
                  scope.row.InventoryQuantity !== scope.row.CheckQuantity)
              "
            >
              <el-upload
                :disabled="authenStatus"
                ref="uploadFrontref"
                :headers="myHeaders"
                :http-request="dataFormSubmit"
                :before-upload="beforeAvatarUpload"
                :on-success="handleposrSuccess"
                :show-file-list="false"
                :on-change="
                  (file, fileList) => {
                    imgSaveToUrl(file, fileList, scope.row.IndexNo);
                  }
                "
                :file-list="fileList"
                :on-remove="handleRemove(scope.row, 'upload')"
              >
                <!-- :on-progress="progress(scope.row)" -->
                <el-button
                  v-if="scope.row.AttachName === null"
                  size="mini"
                  type="primary"
                  @click="clickUploadFile(scope.row)"
                  >选择文件
                </el-button>
                <div
                  v-if="scope.row.AttachName !== null"
                  style="width: 60px; height: 21px"
                >
                  <span
                    style="color: blue"
                    class="hoverDownFile"
                    @click="showLoadFile(scope.row)"
                    >{{ scope.row.AttachName }}</span
                  >
                  <!-- <img
                    @click.stop="openImg(scope.row)"
                    :src="scope.row.AttachPath"
                    alt=""
                    style="width: 100%; height: 100%"
                  /> -->
                </div>
              </el-upload>
              <span
                @click="handleRemove(scope.row, 'click')"
                v-if="scope.row.AttachName !== null"
                class="uploader"
              >
                <i class="el-icon-close"></i
              ></span>
            </div>
            <div v-else>
              <span
                style="color: blue"
                class="hoverDownFile"
                @click="downLoadFile(scope.row, 'clearAndAdmit')"
                >{{ scope.row.AttachName }}</span
              >
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="clearAndAdmit"
        label="盘点状态"
        width="100px"
        align="center"
      >
        <template #default="scope">
          {{ scope.row.CheckStatusName }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="inventoryAndDetail"
        label="操作"
        width="300px"
        align="center"
      >
        <template #default="scope">
          <el-button
            @click.stop="editData(scope.$index, tableData, tableRouter, 'view')"
            type="primary"
            size="small"
            plain
            :disabled="
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === 'view')
                .isDisable
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "view")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="changeSth(scope.$index, tableData)"
            type="success"
            size="small"
            plain
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find(
                  (item) => item.operateId === 'edit'
                ).isDisable) ||
              scope.row.Status !== '04'
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "edit")
                .operateName
            }}
          </el-button>
          <el-button
            @click.stop="planeDelete(scope.$index, tableData)"
            type="danger"
            size="small"
            plain
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find(
                  (item) => item.operateId === 'delete'
                ).isDisable) ||
              scope.row.Status !== '04'
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find(
                (item) => item.operateId === "delete"
              ).operateName
            }}
          </el-button>
          <el-button
            @click.stop="planeClose(scope.$index, tableData)"
            type="danger"
            size="small"
            plain
            :disabled="
              (operateInfo &&
                operateInfo.operateInfo.find(
                  (item) => item.operateId === 'close'
                ).isDisable) ||
              scope.row.Status !== '01'
            "
          >
            {{
              operateInfo &&
              operateInfo.operateInfo.find((item) => item.operateId === "close")
                .operateName
            }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        v-if="inventoryNum"
        label="盘点数量"
        width="130"
        align="center"
      >
        <template #default="scope">
          <div id="input-InnerStyle" v-if="scope.row.CheckStatus === '01'">
            <el-input
              v-model="scope.row.CheckQuantity"
              size="mini"
              @input="changeNum(scope.row)"
              :disabled="scope.row.CancelFlagValue"
              onkeyup="this.value=this.value.match(/^\d{1,10}(\.\d{0,4})?/)?this.value.match(/^\d{1,10}(\.\d{0,4})?/)[0]:''"
            />
          </div>
          <div v-else>
            {{ scope.row.CheckQuantity }}
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { showLoading, hideLoading } from "../utils/loading";
import request from "../utils/request";
export default {
  props: [
    "isAutoShip",
    "againPan",
    "tableData",
    "tableTitle",
    "tableRouter",
    "deleteInformation",
    "tableUserRoot",
    "tableHeight",
    "approval",
    "detail",
    "accountAdjustment",
    "editAndRemove",
    "relocation",
    "accountList",
    "pagePickinglist",
    "roleList",
    "locationList",
    "detailAndUpdata",
    "allocationDetail",
    "unavailablematerialList",
    "MaterialDailyInventoryList",
    "orderList",
    "toOrderList",
    "isCheckBox",
    "orderNumber",
    "materialRemark",
    "uiDetail",
    "operateInfo",
    "PickNumber",
    "PickType",
    "updatePickingIsReturn",
    "MaterialType",
    "AlreadyShipNumber",
    "AlreadySignNumber",
    "showReceiverList",
    "updatePickingPickNumber",
    "creatematerialreturnReason",
    "modifyMaterialreturnNumber",
    "NotAvailableNumber",
    "SalesPrice",
    "isPhotos",
    "SupplierDetail",
    "selection",
    "emptyTip",
    "deliverGoods",
    "showTenDatas",
    "locationCode",
    "lnventoryList",
    "deliveryNumber",
    "ApplyQuantity",
    "awaitDeliverGoods",
    "Tooltip",
    "noticeList",
    "dashboard",
    "materalList",
    "InventoryReportList",
    "InventoryReportDetail",
    "detailAndUpdataMaterialRequest",
    "pickTypeOptions",
    "MaterTypeOptions",
    "inventoryPlan",
    "approvalOperation",
    "AlreadyRecNum",
    "AlreadyShipNum",
    "detailAndUpdataAndDeliverOrReceive",
    "retrieval",
    "detailAndUpdataAndDeliver",
    "detailAndEditAndDelAndRetrieval",
    "zeroSearchFlg",
    "clearAndAdmit",
    "inventoryAndDetail",
    "inventoryNum",
    "inventoryNo",
    "updateMaterial",
    "showPaymentRecord",
    "showCostCenter",
    "rfidList",
    "returngoodInfo",
    "initSelectData",
    "toBeCountedList",
    "srreturnlist",
    "isRecNo",
    "queryTableData",
    "isTransit",
  ],
  data() {
    return {
      // 盘点计划待盘点页面：取消checkbox的disabled值
      //boxirrevocable:false,
      disabled: false,
      data: null,
      Height: "481",
      emptyMessage: " ",
      scrollbar: false,
      CreatedBy: "",
      //
      myHeaders: sessionStorage.getItem("token"),
      idCardFrontPhoto: false,
      localUrl: "",
      createObjecfile: "",
      fileName: "",
      fileList: [],
      authenStatus: false,
      // 高亮
      isHighlightCurrentRow: false,
      value55: true,
      value56: false,
      isCheck: "",
      sortedData: [],
    };
  },
  created() {
    this.calcWidth();
  },
  updated() {
    if (this.initSelectData) {
      let initData = [];
      this.tableData.map((item) => {
        if (item.ischecked) {
          initData.push(item);
        }
      });
      initData.map((item) => {
        this.$refs.multipleTable.toggleRowSelection(item);
      });
    }
    if (this.emptyTip != "") {
      this.emptyMessage = this.emptyTip;
    }
    if (this.showTenDatas) {
      this.Height = 330;
    }
    window.onresize = () => {
      this.calcWidth();
    };
  },
  methods: {
    //成本中心启用
    enableSth(index, tableData) {
      let data = {
        index: index,
        tableData: tableData,
      };
      this.$emit("enableSth", data);
    },
    //成本中心禁用
    disableSth(index, tableData) {
      let data = {
        index: index,
        tableData: tableData,
      };
      this.$emit("disableSth", data);
    },
    //接受盘点计划待盘点父组件的disabled值，取消按钮disabled=true灰化后，取消的checkbox不可再点击
    // getCancelButton(val){
    //   this.boxirrevocable=val;
    // },
    // 下载附件
    downLoadFile(data, type) {
      console.log("下载附件", data, "AttachName", data.AttachName);
      let CheckReportNo = "";
      let IndexNo = data.IndexNo;
      let AttachName = data.AttachName;
      if (type === "clearAndAdmit") {
        CheckReportNo = data.CheckReportNo;
        showLoading(".content");
        request
          .postFileDownload("Inventory/DownLoadClarifyFile", {
            checkReportNo: CheckReportNo,
            indexNo: IndexNo,
          })
          .then((row) => {
            let blob = new Blob([row.data]);
            const object_url = window.URL.createObjectURL(blob);
            var link = document.createElement("a");
            link.href = object_url;
            link.download = AttachName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            hideLoading();
          })
          .catch(() => {
            this.$message({
              showClose: true,
              type: "error",
              message: "没有附件！",
            });
          });
      }
      if (type === "isPhotos") {
        CheckReportNo = data.ReturnNo;
        showLoading(".content");
        request
          .postFileDownload("Receive/DownLoadReturnGoodsFile", {
            returnNo: CheckReportNo,
            indexNo: IndexNo,
          })
          .then((row) => {
            let blob = new Blob([row.data]);
            const object_url = window.URL.createObjectURL(blob);
            var link = document.createElement("a");
            link.href = object_url;
            link.download = AttachName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            hideLoading();
          })
          .catch(() => {
            this.$message({
              showClose: true,
              type: "error",
              message: "没有附件！",
            });
          });
      }
    },
    clickUploadFile(row) {
      this.authenStatus = false;
      // row.AttachName =this.fileName;
      // console.log("row.AttachName", row.AttachName);
      this.$emit("clickUploadFile", row);
    },
    showLoadFile(row) {
      this.authenStatus = true;
      console.log("点击了已经上传的附件", row);
      // this.$emit("changeUploadFile", row)
    },
    //排序
    sortChange(column) {
      this.$emit("sortChange", column, column.order);
      this.sortedData = [...this.tableData].sort((a, b) => {
        if (column.order === "ascending") {
          return a[column.prop] > b[column.prop] ? 1 : -1;
        } else if (column.order === "descending") {
          return a[column.prop] < b[column.prop] ? 1 : -1;
        } else {
          return 0;
        }
      });
    },
    dataFormSubmit(data) {
      // console.log("http请求调用dataFormSubmit事件--data", data,this.createObjecfile);
      if (data) {
        this.idCardFrontPhoto = true;
        this.createObjecfile = data;
      } else {
        this.createObjecfile = "";
      }
      // console.log("http请求调用dataFormSubmit事件----------------data", this.createObjecfile);
    },
    // 图片校验规则
    beforeAvatarUpload(file) {
      console.log(
        "上传之前调用beforeAvatarUpload事件，进行图片校验规则--file",
        file
      );
      // const isJPG = file.type == "image/jpeg" || "image/png" || "image/jpg";
      // console.log(isJPG);
      // const isLt2M = file.size / 1024 / 1024 < 3;
      // if (!isJPG) {
      //   this.$message.error("上传图片只能是 JPG , PNG格式!");
      // }
      // if (!isLt2M) {
      //   console.log("sadasdasd");
      //   this.$message.error("上传图片大小不能超过 3MB!");
      // }
      // return isJPG && isLt2M;
      return true;
    },
    // 文件移除事件
    handleRemove(row, type) {
      // let refName = "uploadFrontref"+ row.IndexNo;
      // console.log("ref",this.$refs);

      // console.log("文件移除",row);
      // console.log("fileList",this.fileList);
      // if(row.AttachName!==null){

      // if(fileLists.length!==0){
      //   this.localUrl = "";
      //   this.createObjecfile = "";
      //   this.idCardFrontPhoto = false;
      //   // this.$refs.uploadFrontref.clearFiles();
      // }
      // this.$refs.uploadFrontref.clearFiles();
      if (type === "click") {
        //         let fileLists = this.$refs.uploadFrontref.uploadFiles;
        // console.log("this.$refs.uploadFrontref",this.$refs.uploadFrontref);
        // console.log("uploadFiles",fileLists);
        // let uploadFilesArr = this.$refs.uploadversion.uploadFiles; //上传文件列表
        // if(uploadFilesArr.length ==0){
        // }else{
        // this.$refs.uploadversion.uploadFiles =[]

        // }
        // this.$refs.uploadFrontref.clearFiles();
        this.$emit("RemoveFile", row);
      }
      // }
    },
    handleposrSuccess(data) {
      console.log("提交成功", data);
    },
    // 预览图片
    openImg(row) {
      this.$alert(
        '<img style="width: 100%;height: 100%;margin-top: 14px;" src="' +
          row.AttachPath +
          '"/>',
        {
          dangerouslyUseHTMLString: true,
          callback: function () {},
        }
      );
    },
    // 转换图片地址
    imgSaveToUrl(event, fileList, index) {
      console.log("fileList", event, fileList, index);
      console.log(
        "change调用imgSaveToUrl事件,作用：转换图片地址（event）",
        event
      );

      // 获取上传图片的本地URL，用于上传前的本地预览
      var URL = null;
      if (window.createObjectURL != undefined) {
        // basic
        URL = window.createObjectURL(event.raw);
      } else if (window.URL != undefined) {
        // mozilla(firefox)
        URL = window.URL.createObjectURL(event.raw);
      } else if (window.webkitURL != undefined) {
        // webkit or chrome
        URL = window.webkitURL.createObjectURL(event.raw);
      }
      // 转换后的地址为 blob:http://xxx/7bf54338-74bb-47b9-9a7f-7a7093c716b5
      this.localUrl = URL;
      // row.AttachName = event.name
      this.fileName = event.name;
      // console.log("转换后的地址为",this.localUrl,'event.name',event.name,'this.$refs.uploadFrontref.mode',this.$refs.uploadFrontref.mode,'event.raw',event.raw);
      console.log("转换后的地址为", this.localUrl, "event.raw", event.raw);
      let data = {
        raw: event.raw,
        url: this.localUrl,
        fileName: this.fileName,
      };
      // this.$emit("changeImg", event.raw)
      this.$emit("changeImg", data);
    },
    // clearNum (row) {
    //   console.log("row.ClarifyQuantity",row.ClarifyQuantity,row.ClarifyReason);
    //   console.log("row.ClarifyReason !== null && row.ClarifyQuantity ===null",row.ClarifyReason !== null && row.ClarifyQuantity ===null);

    //   if (row.ClarifyQuantity ===null) {
    //     if(row.ClarifyReason === null && row.ClarifyReason === ""){
    //               this.$message({
    //       showClose: true,
    //       type: "error",
    //       message:
    //         "请输入澄清数量",
    //     });
    //     return;

    //     }
    //   }
    //   // this.$emit("changeNum");
    // },
    changeIsFP(row) {
      // 是否复盘：相同物料编码，是否复盘同时勾选
      let sameCode = row.INV_ITEM_Code;
      this.tableData.map((item) => {
        if (item.INV_ITEM_Code === sameCode) {
          item.isFP = row.isFP;
          if (item.isFP) {
            item.ReInventoryCheckFlag = 1;
            item.ClarifyQuantity = "";
            item.ClarifyReason = "";
          } else {
            item.ReInventoryCheckFlag = 0;
          }
        }
      });
      if (row.isFP) {
        row.ReInventoryCheckFlag = 1;
        row.ClarifyQuantity = "";
        row.ClarifyReason = "";
      } else {
        row.ReInventoryCheckFlag = 0;
      }
    },
    // 关闭loading
    closeLoading() {
      this.$emit("closeLoading", false);
    },
    toDeliverGoods(row) {
      this.$router.push({
        name: "createNumber",
        params: {
          data: JSON.stringify(row),
        },
      });
    },
    calcWidth() {
      if (document.body.clientHeight > 577) {
        if (this.showTenDatas) {
          this.Height = document.body.clientHeight - 607;
        } else {
          // 用户列表
          if (this.accountList) {
            this.Height = document.body.clientHeight - 345;
          } else {
            this.Height = document.body.clientHeight - 355;
          }
        }
      } else {
        this.Height = document.body.clientHeight - 367;
        if (this.scrollbar) {
          this.Height = document.body.clientHeight - 357;
        }
        if (document.body.clientWidth < 925) {
          this.Height = document.body.clientHeight - 357;
        }
        if (document.body.clientHeight === 577 && this.pagePickinglist) {
          this.Height = document.body.clientHeight - 357;
        }
      }
      if (this.dashboard) {
        // this.Height = document.body.clientHeight - 157;
        this.Height = document.body.clientHeight - 175;
      }
      if (document.body.clientHeight < 419.333) {
        this.Height = 62;
      }
    },
    isStretching() {
      if (this.scrollbar) {
        this.Height = document.body.clientHeight - 357;
      }
    },

    openDialog(index, data, type) {
      console.log("ccccccccccc", index, data, type);
      if (type === "shanchu") {
        if (
          this.orderNumber ||
          this.PickNumber ||
          this.updatePickingPickNumber ||
          this.modifyMaterialreturnNumber ||
          this.deliveryNumber ||
          this.NotAvailableNumber ||
          this.inventoryPlan ||
          this.deleteInformation
        ) {
          this.$emit("deleteOrderInfo", index, data);
        } else {
          this.data = {
            index: index,
            data: data,
          };
          this.dialogVisible = true;
          this.commonDialogTitle = "删除";
          this.showMessage = "确定删除该条数据吗？";
        }
      }
      if (type === "yiku") {
        this.$emit("openDialog", index, data);
      }
      if (type === "qiyong") {
        this.$emit("openDialogValid", index, data);
      }
      if (type === "jinyong") {
        this.$emit("openDialogInvalid", index, data);
      }
      if (type === "details") {
        this.$emit("openDialogDetails", index, data);
      }
    },
    checkRoot(index, data, type) {
      this.$emit("checkRoot", index, data, type);
    },
    deleteData(index, data, type) {
      this.$emit("deleteData", index, data, type);
    },
    editData(index, tableData, tableRouter, type) {
      console.log(index, tableData, tableRouter, type);
      // 使用排序后的数据进行操作
      const sortedTableData = this.sortedData.length
        ? this.sortedData
        : this.tableData;
      this.$router.push({
        name: tableRouter,
        params: {
          index: index,
          OrderNo: sortedTableData[index].OrderNo
            ? JSON.stringify(sortedTableData[index].OrderNo)
            : "",
          applyNo: sortedTableData[index].ApplyNo
            ? JSON.stringify(sortedTableData[index].ApplyNo)
            : "",
          tableData: JSON.stringify(sortedTableData),
          type: type,
        },
      });
    },
    toReceiverDetail(index, tableData, type) {
      let data = tableData[index];
      let RecType = data.RecType;
      let recNo = data.RecNo;
      let UpdatedTime = data.UpdatedTime;
      let routeName = "receiverofinvoicedetail";
      // if (RecType === "01") {
      //   //供应商收货详情
      //   routeName = "receiverofinvoicedetail";
      // } else {
      //   //调拨单收货详情
      //   routeName = "receiveroftransferdetail";
      // }
      this.$router.push({
        name: routeName,
        params: {
          recNo: recNo,
          RecType: RecType,
          UpdatedTime: UpdatedTime,
          type: type,
        },
      });
    },
    closeDialog() {
      this.deleteDialogVisible = false;
      this.saveDialogType();
    },
    confirmDialog() {
      this.$emit("toConfirm", this.data);
      this.deleteDialogVisible = false;
      this.saveDialogType();
    },
    changeSth(index, tableData) {
      this.data = {
        index: index,
        data: tableData,
      };
      this.$emit("changeSth", this.data);
    },
    // 供应商填补率查看
    viewFill(index, tableData) {
      this.data = {
        index: index,
        data: tableData,
      };
      this.$emit("viewFill", this.data);
    },
    // 盘点计划删除
    planeDelete(index, tableData) {
      let data = {
        index: index,
        data: tableData,
      };
      this.$emit("planeDelete", data);
    },
    // 盘点计划关闭
    planeClose(index, tableData) {
      let data = {
        index: index,
        data: tableData,
      };
      this.$emit("planeClose", data);
    },
    changeWithDraw(index, tableData) {
      this.data = {
        index: index,
        data: tableData,
      };
      this.$emit("changeWithDraw", this.data);
    },
    changeScrap(index, tableData) {
      this.data = {
        index: index,
        data: tableData,
      };
      this.$emit("changeScrap", this.data);
    },
    close(index) {
      this.$emit("close", index);
    },
    order(index) {
      this.$emit("order", index);
    },
    handleSelectionChange(data) {
      this.$emit("selectData", data);
    },
    //付款页面禁止全选
    selectAll() {
      if (this.showPaymentRecord) {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleCellClick(row, column) {
      if (column.property) {
        this.$emit("handleCellClick", row);
      }
    },
    //表格内勾选事件
    handleRowClick(row) {
      //控制单选，付款页面选择多个表格时
      if (this.showPaymentRecord) {
        //每次勾选时清空上一次的勾选参数
        this.$emit("changeExportData");
        this.$refs.multipleTable.clearSelection();
        this.$refs.multipleTable.toggleRowSelection(row, true);
      } else {
        this.isHighlightCurrentRow = true;
        if (this.$refs.multipleTable != null) {
          this.$refs.multipleTable.toggleRowSelection(row);
        }
      }
    },
    //列表单选的勾选控制只勾选cell框时清空勾选。
    //（因为handleRowClick方法只能清除表勾选，单独勾选checkbox清楚不掉）
    select(selection) {
      //每次勾选时清空上一次的勾选参数
      this.$emit("changeExportData");
      //如果是付款页面且勾选的checkbox大于1即selection.length>1
      if (this.showPaymentRecord && selection.length > 1) {
        //selection.shift()被勾选的框。
        let del_row = selection.shift();
        this.$refs.multipleTable.toggleRowSelection(del_row, false);
      }
    },
    changeNum(row) {
      if (row.RecNumber != "" || row.RecNumber != 0) {
        if (row.RecNumber > row.ShipNumber) {
          this.$message({
            showClose: true,
            type: "error",
            message:
              "物料编码:" +
              row.INV_ITEM_Code +
              ",物料名称:" +
              row.INV_ITEM_Name +
              ",收货数量不能大于发货数量" +
              row.ShipNumber,
          });
        }
      }
      this.$emit("changeNum", row);
    },
    changeSwitch(row) {
      this.$emit("changeSwitch", row);
    },
    // 获取焦点时，不可输入，属性设为true
    onFocus() {
      this.disabled = true;
    },
    // 失去焦点时，可输入，属性设为false
    onBlur() {
      this.disabled = false;
    },
    pickSelect(data) {
      this.disabled = false;
      this.$emit("pickSelect", data);
    },
    deliveryData(data) {
      this.$emit("deliveryData", data);
    },
    materialSelect(data) {
      this.$emit("materialSelect", data);
    },
    SalsePrice() {
      this.$emit("SalsePrice");
    },
    changeScopeRowSingleNumber(row, type, path) {
      // if (path === "deliveryNumber") {
      //   if (item.ShipQuantity < 0) {
      //     item.ShipQuantity = 0;
      //   }
      //   if (type === "" && item.ShipQuantity === 0) {
      //     item.ShipQuantity = "";
      //   }
      //   if (type === 0 && item.ShipQuantity === "") {
      //     item.ShipQuantity = 0;
      //   }
      // }
      if (path === "deliveryNumber") {
        if (row.ShipQuantity < 0 || row.ShipQuantity === null) {
          row.ShipQuantity = 0;
        }
        if (type === "" && row.ShipQuantity === 0) {
          row.ShipQuantity = "";
        }
        if (type === "" && row.ShipQuantity === "0") {
          row.ShipQuantity = "";
        }
        if (type === 0 && row.ShipQuantity === "") {
          row.ShipQuantity = 0;
        }
      }
      // if (path === "inventoryDetail") {
      //   if (row.CheckQuantity < 0 || row.CheckQuantity === null) {
      //     row.CheckQuantity = 0;
      //   }
      //   if (type === "" && row.CheckQuantity === 0) {
      //     row.CheckQuantity = "";
      //   }
      //   if (type === "" && row.CheckQuantity === "0") {
      //     row.CheckQuantity = "";
      //   }
      //   if (type === 0 && row.CheckQuantity === "") {
      //     row.CheckQuantity = 0;
      //   }
      // }
      if (path === "addPicking") {
        if (row.Number < 0 || row.Number === null) {
          row.Number = 0;
        }
        if (type === "" && row.Number === 0) {
          row.Number = "";
        }
        if (type === "" && row.Number === "0") {
          row.Number = "";
        }
        if (type === 0 && row.Number === "") {
          row.Number = 0;
        }
      }
      if (path === "updatePicking") {
        if (row.InventoryNumber < 0 || row.InventoryNumber === null) {
          row.InventoryNumber = 0;
        }
        if (type === "" && row.InventoryNumber === 0) {
          row.InventoryNumber = "";
        }
        if (type === "" && row.InventoryNumber === "0") {
          row.InventoryNumber = "";
        }
        if (type === 0 && row.InventoryNumber === "") {
          row.InventoryNumber = 0;
        }
      }
      if (path === "createOrder") {
        if (row.Number < 0 || row.Number === null) {
          row.Number = 0;
        }
        if (type === "" && row.Number === 0) {
          row.Number = "";
        }
        if (type === "" && row.Number === "0") {
          row.Number = "";
        }
        if (type === 0 && row.Number === "") {
          row.Number = 0;
        }
      }
      //gbx,改path和number：页面的数量名称   在这里v-if="modifyMaterialreturnNumber"label="退料数量"传path
      if (path === "modifyMaterialreturnNumber") {
        if (row.ReturnNumber < 0 || row.ReturnNumber === null) {
          row.ReturnNumber = 0;
        }
        if (type === "" && row.ReturnNumber === 0) {
          row.ReturnNumber = "";
        }
        if (type === "" && row.ReturnNumber === "0") {
          row.ReturnNumber = "";
        }
        if (type === 0 && row.ReturnNumber === "") {
          row.ReturnNumber = 0;
        }
        if (
          isNaN(Number(row.ReturnNumber)) ||
          (Number(row.ReturnNumber) === 0 && type === "")
        ) {
          row.ReturnNumber = "";
        }
      }
      if (path === "receipt") {
        if (row.RecNumber < 0 || row.RecNumber === null) {
          row.RecNumber = 0;
        }
        if (type === "" && row.RecNumber === 0) {
          row.RecNumber = "";
        }
        if (type === "" && row.RecNumber === "0") {
          row.RecNumber = "";
        }
        if (type === 0 && row.RecNumber === "") {
          row.RecNumber = 0;
        }
        if (
          isNaN(Number(row.RecNumber)) ||
          (Number(row.RecNumber) === 0 && type === "")
        ) {
          row.RecNumber = "";
        }
      }
      if (path === "ApplyQuantity") {
        if (row.ApplyQuantity < 0 || row.ApplyQuantity === null) {
          row.ApplyQuantity = 0;
        }
        if (type === "" && row.ApplyQuantity === 0) {
          row.ApplyQuantity = "";
        }
        if (type === "" && row.ApplyQuantity === "0") {
          row.ApplyQuantity = "";
        }
        if (type === 0 && row.ApplyQuantity === "") {
          row.ApplyQuantity = 0;
        }
        if (
          isNaN(Number(row.ApplyQuantity)) ||
          (Number(row.ApplyQuantity) === 0 && type === "")
        ) {
          row.ApplyQuantity = "";
        }
      }
      //例子换路径，换数量
      //       if (path === "inventoryDetail") {
      //   if (row.RecNumber < 0 || row.RecNumber===null) {
      //     row.RecNumber = 0;
      //   }
      //   if (type === "" && row.RecNumber === 0) {
      //     row.RecNumber = "";
      //   }
      //   if (type === "" && row.RecNumber === "0") {
      //     row.RecNumber = "";
      //   }
      //   if (type === 0 && row.RecNumber === "") {
      //     row.RecNumber = 0;
      //   }
      // }
    },
    changeScopeRowNumber(data, type, path) {
      this.tableData.map((item) => {
        if (data.index_id === item.index_id) {
          if (path === "updatePicking") {
            if (item.InventoryNumber < 0) {
              item.InventoryNumber = 0;
            }
            if (type === "" && item.InventoryNumber === 0) {
              item.InventoryNumber = "";
            }
            if (type === 0 && item.InventoryNumber === "") {
              item.InventoryNumber = 0;
            }
          }
          if (path === "addPicking") {
            if (item.Number < 0) {
              item.Number = 0;
            }
            if (type === "" && item.Number === 0) {
              item.Number = "";
            }
            if (type === 0 && item.Number === "") {
              item.Number = 0;
            }
          }
          if (path === "createOrder") {
            if (item.Number < 0) {
              item.Number = 0;
            }
            if (type === "" && item.Number === 0) {
              item.Number = "";
            }
            if (type === 0 && item.Number === "") {
              item.Number = 0;
            }
            if (type === 0 && item.DeliveryDate === "") {
              item.DeliveryDate = "";
            }
          }
          if (path === "deliveryNumber") {
            if (item.ShipQuantity < 0) {
              item.ShipQuantity = 0;
            }
            if (type === "" && item.ShipQuantity === 0) {
              item.ShipQuantity = "";
            }
            if (type === 0 && item.ShipQuantity === "") {
              item.ShipQuantity = 0;
            }
          }
          if (path === "receipt") {
            if (item.RecNumber < 0) {
              item.RecNumber = 0;
            }
            if (type === "" && item.RecNumber === 0) {
              item.RecNumber = "";
            }
            if (type === 0 && item.RecNumber === "") {
              item.RecNumber = 0;
            }
          }
          if (path === "ApplyQuantity") {
            if (item.ApplyQuantity < 0) {
              item.ApplyQuantity = 0;
            }
            if (type === "" && item.ApplyQuantity === 0) {
              item.ApplyQuantity = "";
            }
            if (type === 0 && item.ApplyQuantity === "") {
              item.ApplyQuantity = 0;
            }
          }
          if (path === "InventoryReportDetail") {
            if (item.ClarifyQuantity < 0) {
              item.ClarifyQuantity = 0;
            }
            // if (type === "" && item.ClarifyQuantity === 0) {
            //   item.ClarifyQuantity = "";
            // }
            // if (type === 0 && item.ClarifyQuantity === "") {
            //   item.ClarifyQuantity = 0;
            // }
          }
          // if (path === "inventoryDetail") {
          //   if (item.CheckQuantity < 0) {
          //     item.CheckQuantity = 0;
          //   }
          //   if (type === "" && item.CheckQuantity === 0) {
          //     item.CheckQuantity = "";
          //   }
          //   if (type === 0 && item.CheckQuantity === "") {
          //     item.CheckQuantity = 0;
          //   }
          // }
        }
      });
    },
    changeScopeRowValue(index, row, type, path) {
      let table = this.tableData;
      let mapTable = table.slice(0, index);
      console.log(index, row, table, mapTable, type, path);

      // 收货时检查库位编码是否已经存在
      // if (path === "receipt") {
      //   let isFind = mapTable.find(
      //     (find) => find.LocationEditNo === row.LocationEditNo
      //   );
      //   if (isFind !== undefined) {
      //     this.$message({
      //       type: "error",
      //       showClose: true,
      //       message: isFind.LocationEditNo + "库位编码已经存在，请重新输入",
      //     });
      //     row.LocationEditNo = "";
      //   }
      // }
    },
    split(index) {
      this.$emit("split", index);
    },
    toAwaitDeliverGoods(row) {
      this.$emit("toAwaitDeliverGoods", row);
    },
    tabRowClassName({ row, rowIndex }) {
      if (
        this.isTransit &&
        this.queryTableData.length > 0 &&
        row.INV_ITEM_Code
      ) {
        const isTrue = this.queryTableData.find(
          (item) => item.INV_ITEM_Code == row.INV_ITEM_Code
        );
        if (isTrue) {
          return "transitClassName";
        }
      }
      let index = rowIndex;
      if (index % 2 == 0) {
        return "tabRowClassName";
      } else {
        return "";
      }
    },
    querySearch(queryString, cb) {
      console.log(queryString, cb);
      // 穿过来的 option pickTypeOptions
      var restaurants = this.pickTypeOptions;
      // var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(restaurants);
    },
    MaterSearch(queryString, cb) {
      console.log(queryString, cb);
      // 穿过来的 option pickTypeOptions
      var restaurants = this.MaterTypeOptions;
      // var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(restaurants);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    suggestions(row) {
      console.log(row);
      // row.pickType = ''
      // this.querySearch(' ')
    },
    approvalEdit(data) {
      console.log("审批修改", data);
      this.$emit("approvalEdit", data);
    },
    retrievalClick(row) {
      this.$emit("retrievalEvent", row);
    },
    deliverClick(row) {
      this.$emit("deliverEvent", row);
    },
    receiveClick(row) {
      this.$emit("receiveEvent", row);
    },
    // 打印
    Printtranslation(index, tableData) {
      this.$emit("Printtranslation", index, tableData);
    },
  },
  mounted() {
    //获取当前登陆人
    this.CreatedBy = JSON.parse(
      sessionStorage.getItem("loginUserInfo")
    ).accountId.toLowerCase();
    console.log("this.CreatedBy", this.CreatedBy);
    if (this.emptyTip != "") {
      this.emptyMessage = this.emptyTip;
    }
    const scrollbarwidth = document.body.clientWidth;
    if (scrollbarwidth >= 1280) {
      this.scrollbar = false;
    } else {
      this.scrollbar = true;
    }
    window.addEventListener(
      "resize",
      () => {
        this.calcWidth();
        const scrollbarwidth = document.body.clientWidth;
        if (scrollbarwidth >= 1280) {
          this.scrollbar = false;
        } else {
          this.scrollbar = true;
        }
      },
      false
    );
  },
  watch: {
    tableData: {
      deep: true,
      handler: function (value) {
        console.log("检测数据变化", value);
        if (value.length !== 0) {
          // console.log("长度不为0");
          this.$nextTick(function () {
            // console.log("回调中关闭");
            this.closeLoading();
          });
        } else {
          // console.log("长度为0",this.zeroSearchFlg);
          if (this.zeroSearchFlg) {
            // console.log("未找到匹配记录，关闭Loading");
            this.closeLoading();
          }
          // console.log("------------------");
        }
      },
    },
  },
};
</script>
<style>
#commonTable .el-table__fixed {
  background: #ffffff;
}
</style>
<style lang="less" scoped>
.el-table /deep/ th {
  text-align: center;
  padding: 0;
  min-height: 30px;
  height: 30px;
}
.el-table /deep/ td {
  padding: 0;
  min-height: 30px;
  height: 30px;
}

.el-table /deep/.el-button--mini {
  min-height: 22px;
  font-size: 12px;
  border-radius: 3px;
}

.el-table /deep/ .el-button--mini,
.el-button--mini.is-round {
  padding: 1px 15px;
}
#input-InnerStyle /deep/ .el-input--mini .el-input__inner {
  height: 22px;
  line-height: 22px;
  width: 90px;
}
#input-price /deep/ .el-input--mini .el-input__inner {
  height: 22px;
  line-height: 22px;
  width: 70 px;
}
#input-InnerReasonStyle /deep/ .el-input--mini .el-input__inner {
  height: 22px;
  line-height: 22px;
  width: 130px;
}
.el-table thead {
  color: #23282c !important;
}
.el-table--striped .el-table__body tr.el-table__row--striped td {
  background: rgba(0, 0, 0, 0.05) !important;
}
.el-table--border,
.el-table--group {
  border-top: 1px solid #ddd !important;
  border-left: 1px solid #ddd !important;
  // border-right: 1px solid #ddd !important;
}
// 表格td--border
/deep/ .el-table td,
.el-table th.is-leaf {
  border-bottom: 1px solid #ddd !important;
}
// 表格td--border
/deep/ .el-table--border td,
.el-table--border th,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: 1px solid #ddd !important;
}
.el-table {
  color: #23282c !important;
}
.el-table .descending .sort-caret.descending {
  border-top-color: #fff !important;
}
.el-table .ascending .sort-caret.ascending {
  border-bottom-color: #fff !important;
}
/* .el-table .warning-row {
  background: #f3f9ff;
} */

/deep/.el-table .tabRowClassName {
  background: rgba(0, 0, 0, 0.05) !important;
  /* background: #30659b; */
}
.el-table .cell {
  white-space: nowrap !important;
}
//表格th--border
/deep/ .el-table--border th,
.el-table__fixed-right-patch {
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd !important;
}
/deep/.el-input--small .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
  width: 125px;
}
/deep/.el-input--small {
  line-height: 26px !important;
}
/deep/.el-input--small .el-input__icon {
  line-height: 28px !important;
}
/deep/.el-input--mini .el-input__inner {
  height: 28px !important;
  line-height: 26px;
}
/deep/.el-input--suffix .el-input__inner {
  padding-right: 16px;
}
.el-button--success.is-disabled,
.el-button--success.is-disabled:active,
.el-button--success.is-disabled:focus,
.el-button--success.is-disabled:hover {
  color: #23282c !important;
  background-color: #f2f2f2 !important;
  border-color: #ddd !important;
}
.selectjs {
  .selectjs:hover {
    cursor: text !important;
  }
  .selectjs_min {
    width: 100%;
    height: 28px !important;
    line-height: 28px !important;
    background-color: #fff;
    border-radius: 4px !important;
    border: 1px solid #dcdfe6;
    outline: 0;
    // padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    cursor: text;
    font-size: 12px;
  }
  .selectjs_min:hover {
    border: 1px solid #bdbdbe !important;
  }
}
// .selectjs:after {
//   content: "";
//   width: 14px;
//   height: 8px;
//   // background: url(../assets/arrow-down.png) no-repeat center;
//   /*通过定位将图标放在合适的位置*/
//   position: absolute;
//   top: 0;
//   // right: 20px;
//   // top: 41%;
//   /*给自定义的图标实现点击下来功能*/
//   pointer-events: none;
// }
.selectjs select {
  border: none;
  /*清除select聚焦时候的边框颜色*/
  outline: none;
  /*将select的宽高等于div的宽高*/
  width: 100%;
  // height: 40px;
  // line-height: 40px;
  /*隐藏select的下拉图标*/
  // appearance: none;
  // -webkit-appearance: none;
  // -moz-appearance: none;
  /*通过padding-left的值让文字居中*/
  padding-left: 5px;
}
/deep/.el-autocomplete-suggestion__wrap {
  max-height: 121px !important;
}
#pickType /deep/ .el-autocomplete-suggestion__wrap {
  max-height: 140px !important;
}
/*隐藏滚动条*/
/deep/::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #bfbfbf;
}
/deep/::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #bfbfbf;
}
/deep/::-webkit-scrollbar-thumb:hover {
  background-color: #333 !important;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #333 !important;
}
/deep/.nowrap-tab .el-table__header .cell {
  padding: 0 !important;
  text-overflow: unset !important;
  white-space: nowrap !important;
}
// .overflow-x:scroll;{

// }
.scrollbar {
  overflow-x: scroll !important;
}
/deep/ .el-table--border td:first-child .cell,
.el-table--border th:first-child .cell {
  padding-left: 14px;
}
.hoverDownFile {
  cursor: pointer;
}
// 附件关闭图标样式
/deep/.uploader {
  cursor: pointer;
  font-size: 14px;
  position: absolute;
  top: -4px;
  right: 15px;
}
// table选中行高亮样式设置
/deep/.el-table__body tr.current-row > td {
  background: #ffffcc !important;
}
//数量表头居中
/deep/.el-table th.is-right {
  text-align: center;
}
.tableScopeSwitch {
  margin-right: 5px;
}
.tableScopeSwitch ::v-deep .el-switch__label {
  position: absolute;
  display: none;
  color: #fff;
  font-size: 12px;
}
::v-deep .el-switch.is-checked .el-switch__core::after {
  left: 100%;
  margin-left: -20px;
}
/*打开时文字位置设置*/
.tableScopeSwitch ::v-deep .el-switch__label--right {
  z-index: 1;
  left: 2px; /*不同场景下可能不同，自行调整*/
  height: 23px;
  top: 0px;
}
.tableScopeSwitch ::v-deep .el-switch__core:after {
  width: 20px;
  height: 20px;
}
/*关闭时文字位置设置*/
.tableScopeSwitch ::v-deep .el-switch__label--left {
  z-index: 1;
  right: 2px; /*不同场景下可能不同，自行调整*/
  height: 23px;
}
/*显示文字*/
.tableScopeSwitch ::v-deep .el-switch__label.is-active {
  display: block;
}
::v-deep.el-switch .is-checked .el-switch__core::after {
  left: 100%;
}
.tableScopeSwitch ::v-deep .el-switch__core,
::v-deep .el-switch {
  width: 65px !important; /*开关按钮的宽度大小*/
  height: 20px;
  line-height: 23px;
  border-radius: 13px;
}
/deep/.el-table .transitClassName {
  background: rgba(17, 160, 232, 0.68) !important;
  color: red !important;
}
</style>
