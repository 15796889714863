import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";
import Store from "../store/index.js";

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "/userList",
        name: "userList",
        meta: {
          title: "角色权限管理",
          refreshPath: "/",
        },
        component: () => import("../userRoot/UserList.vue"),
      },
      {
        path: "/checkRoot",
        name: "checkRoot",
        meta: {
          title: "查看权限",
          parentPath: "/userList",
          parentTitle: "角色权限管理",
          parentName: "userList",
          refreshPath: "/",
        },
        component: () => import("../userRoot/CheckRoot.vue"),
      },
      {
        path: "/sysuserlist",
        name: "sysuserlist",
        meta: {
          title: "用户管理",
          refreshPath: "/",
        },
        component: () => import("../userManagement/UserManagement.vue"),
      },
      {
        path: "/addUser",
        name: "addUser",
        meta: {
          title: "添加用户",
          parentPath: "/sysuserlist",
          parentTitle: "用户管理",
          parentName: "sysuserlist",
          refreshPath: "/",
        },
        component: () => import("../userManagement/addUser.vue"),
      },
      {
        path: "/modifyUser",
        name: "modifyUser",
        meta: {
          title: "修改用户",
          parentPath: "/sysuserlist",
          parentTitle: "用户管理",
          parentName: "sysuserlist",
          refreshPath: "/",
        },
        component: () => import("../userManagement/modifyUser.vue"),
      },
      {
        path: "/materiallist",
        name: "materiallist",
        meta: {
          title: "物料列表",
          refreshPath: "/",
        },
        component: () => import("../materialManagement/materialList.vue"),
      },
      {
        path: "/materialdetail",
        name: "materialdetail",
        meta: {
          title: "物料详情",
          parentPath: "/materiallist",
          parentTitle: "物料列表",
          parentName: "materiallist",
          refreshPath: "/",
        },
        component: () => import("../materialManagement/materialDetail.vue"),
      },
      {
        path: "/materialmodify",
        name: "materialmodify",
        meta: {
          title: "修改物料",
          parentPath: "/materiallist",
          parentTitle: "物料列表",
          parentName: "materiallist",
          refreshPath: "/",
        },
        component: () => import("../materialManagement/materialModify.vue"),
      },
      {
        path: "/importmaterial",
        name: "importmterial",
        meta: {
          title: "导入物料",
          parentPath: "/materiallist",
          refreshPath: "/",
        },
        component: () => import("../materialManagement/importMaterial.vue"),
      },
      {
        path: "/materialapplylist",
        name: "materialapplylist",
        meta: {
          title: "新物料申请列表",
          refreshPath: "/",
        },
        component: () => import("../materialManagement/materialApplyList.vue"),
      },
      {
        path: "/materialapplydetail",
        name: "materialapplydetail",
        meta: {
          title: "新物料详情",
          parentPath: "/materialapplylist",
          parentTitle: "新物料申请列表",
          parentName: "materialapplylist",
          refreshPath: "/",
        },
        component: () =>
          import("../materialManagement/materialApplyDetail.vue"),
      },
      {
        path: "/newAddPicking",
        name: "newAddPicking",
        meta: {
          title: "创建新物料",
          parentPath: "/materialapplylist",
          parentTitle: "新物料申请列表",
          parentName: "materialapplylist",
          refreshPath: "/",
        },
        component: () => import("../views/pickingmanagement/newAddPicking.vue"),
      },
      {
        path: "/keyMaterialList",
        name: "keyMaterialList",
        meta: {
          title: "关键性物料列表",
          refreshPath: "/",
        },
        component: () => import("../keyMaterialManagement/KeyMaterialList.vue"),
      },
      {
        path: "/editKeyMaterial",
        name: "editKeyMaterial",
        meta: {
          title: "关键性物料详情",
          parentPath: "/keyMaterialList",
          parentTitle: "关键性物料列表",
          parentName: "keyMaterialList",
          refreshPath: "/",
        },
        component: () => import("../keyMaterialManagement/EditKeyMaterial.vue"),
      },
      {
        path: "/stagnantmaterialList",
        name: "stagnantmaterialList",
        meta: {
          title: "呆滞物料列表",
          refreshPath: "/",
        },
        component: () =>
          import("../stagnantmaterialmanagement/StagnantmaterialList.vue"),
      },
      {
        path: "/importStagnantMaterial",
        name: "importStagnantMaterial",
        meta: {
          title: "导入呆滞物料",
          parentPath: "/longmateriallist",
          parentTitle: "长账龄物料列表",
          parentName: "stagnantmaterialList",
          refreshPath: "/",
        },
        component: () =>
          import("../stagnantmaterialmanagement/importStagnantMaterial.vue"),
      },
      {
        path: "/stagnantMaterialApplicationList",
        name: "stagnantMaterialApplicationList",
        meta: {
          title: "呆滞物料申请列表",
          refreshPath: "/",
        },
        component: () =>
          import(
            "../stagnantmaterialmanagement/stagnantMaterialApplicationList.vue"
          ),
      },
      {
        path: "/stagnantMaterialApplicationDetail",
        name: "stagnantMaterialApplicationDetail",
        meta: {
          title: "呆滞物料申请详情",
          parentPath: "/stagnantMaterialApplicationList",
          parentTitle: "呆滞物料申请列表",
          parentName: "stagnantMaterialApplicationList",
          refreshPath: "/",
        },
        component: () =>
          import(
            "../stagnantmaterialmanagement/stagnantMaterialApplicationDetail.vue"
          ),
      },
      {
        path: "/stagnantMaterialAppication",
        name: "stagnantMaterialAppication",
        meta: {
          title: "呆滞物料申请",
          parentPath: "/stagnantMaterialApplicationList",
          parentTitle: "呆滞物料申请列表",
          parentName: "stagnantMaterialApplicationList",
          refreshPath: "/",
        },
        component: () =>
          import(
            "../stagnantmaterialmanagement/stagnantMaterialAppication.vue"
          ),
      },
      {
        path: "/stagnantMaterialModify",
        name: "stagnantMaterialModify",
        meta: {
          title: "呆滞物料申请修改",
          parentPath: "/stagnantMaterialApplicationList",
          parentTitle: "呆滞物料申请列表",
          parentName: "stagnantMaterialApplicationList",
          refreshPath: "/",
        },
        component: () =>
          import("../stagnantmaterialmanagement/stagnantMaterialModify.vue"),
      },
      {
        path: "/unavailablematerialList",
        name: "unavailablematerialList",
        meta: {
          title: "不可用物料处置列表",
          refreshPath: "/",
        },
        component: () =>
          import("../stagnantmaterialmanagement/UnavailablematerialList.vue"),
      },
      {
        path: "/editUnavailablematerialList",
        name: "editUnavailablematerialList",
        meta: {
          title: "不可用物料处置申请",
          parentPath: "/unavailablematerialList",
          parentTitle: "不可用物料处置申请",
          parentName: "unavailablematerialList",
          refreshPath: "/",
        },
        component: () =>
          import(
            "../stagnantmaterialmanagement/EditUnavailablematerialList.vue"
          ),
      },
      {
        path: "/UnavailableItemDetails",
        name: "UnavailableItemDetails",
        meta: {
          title: "不可用物料处置详情",
          parentPath: "/unavailablematerialList",
          parentTitle: "不可用物料处置详情",
          parentName: "unavailablematerialList",
          refreshPath: "/",
        },
        component: () =>
          import("../stagnantmaterialmanagement/UnavailableItemDetails.vue"),
      },
      {
        path: "/ModifyUnavailblematerList",
        name: "ModifyUnavailblematerList",
        meta: {
          title: "不可用物料处置修改",
          parentPath: "/unavailablematerialList",
          parentTitle: "不可用物料处置修改",
          parentName: "unavailablematerialList",
          refreshPath: "/",
        },
        component: () =>
          import("../stagnantmaterialmanagement/ModifyUnavailblematerList.vue"),
      },
      {
        path: "/inventoryplanList",
        name: "inventoryplanList",
        meta: {
          title: "盘点计划列表",
          refreshPath: "/",
        },
        component: () => import("../inventorymanagement/InventoryplanList.vue"),
      },
      // 盘点计划编辑页面
      {
        path: "/EditInventoryplan",
        name: "EditInventoryplan",
        meta: {
          title: "盘点计划",
          parentPath: "/inventoryplanList",
          parentTitle: "盘点计划列表",
          parentName: "inventoryplanList",
          refreshPath: "/",
        },
        component: () =>
          import("../inventorymanagement/EditInventoryplan.vue"),
      },
      {
        path: "/InventoryplanListCreate",
        name: "InventoryplanListCreate",
        meta: {
          title: "创建日盘",
          parentPath: "/MaterialDailyInventoryList",
          parentTitle: "库房动态物料日盘列表",
          parentName: "MaterialDailyInventoryList",
          refreshPath: "/",
        },
        component: () =>
          import("../inventorymanagement/InventoryplanListCreate.vue"),
      },
      {
        path: "/inventoryDetail",
        name: "inventoryDetail",
        meta: {
          title: "盘点详情",
          parentPath: "/inventoryplanList",
          parentTitle: "盘点计划列表",
          parentName: "inventoryplanList",
          refreshPath: "/",
        },
        component: () => import("../inventorymanagement/InventoryDetail.vue"),
      },
      // {
      //   path: "/inventorydateList",
      //   name: "inventorydateList",
      //   meta: {
      //
      //   },
      //   component: () => import("../inventorymanagement/InventorydateList.vue"),
      // },
      {
        path: "/accountadjustmentList",
        name: "accountadjustmentList",
        meta: {
          title: "调账处理列表",
          refreshPath: "/",
        },
        component: () =>
          import("../inventorymanagement/AccountadjustmentList.vue"),
      },
      {
        path: "/toAgainInventoryList",
        name: "toAgainInventoryList",
        meta: {
          title: "待复盘列表",
          refreshPath: "/",
        },
        component: () => import("../inventorymanagement/toAgainInventoryList.vue"),
      },
      {
        path: "/ToBeCountedList",
        name: "ToBeCountedList",
        meta: {
          title: "待盘点列表",
          refreshPath: "/",
        },
        component: () => import("../inventorymanagement/ToBeCountedList.vue"),
      },
      {
        path: "/InventoryReportList",
        name: "InventoryReportList",
        meta: {
          title: "盘点报告列表",
          refreshPath: "/",
        },
        component: () =>
          import("../inventorymanagement/InventoryReportList.vue"),
      },
      {
        path: "/InventoryReportDetail",
        name: "InventoryReportDetail",
        meta: {
          title: "盘点报告详情",
          parentPath: "/InventoryReportList",
          parentTitle: "盘点报告列表",
          parentName: "InventoryReportList",
          refreshPath: "/",
        },
        component: () =>
          import("../inventorymanagement/InventoryReportDetail.vue"),
      },
      {
        path: "/MaterialDailyInventoryList",
        name: "MaterialDailyInventoryList",
        meta: {
          title: "库房动态物料日盘列表",
          refreshPath: "/",
        },
        component: () =>
          import("../inventorymanagement/MaterialDailyInventoryList.vue"),
      },
      {
        path: "/MaterialDailyInventoryDetail",
        name: "MaterialDailyInventoryDetail",
        meta: {
          title: "库房动态物料日盘详情",
          parentPath: "/MaterialDailyInventoryList",
          parentTitle: "库房动态物料日盘列表",
          parentName: "MaterialDailyInventoryList",
          refreshPath: "/",
        },
        component: () =>
          import("../inventorymanagement/MaterialDailyInventoryDetail.vue"),
      },
      {
        path: "/createInventoryplan",
        name: "createInventoryplan",
        meta: {
          title: "盘点计划",
          parentPath: "/ToBeCountedList",
          parentTitle: "盘点计划列表",
          parentName: "ToBeCountedList",
          refreshPath: "/",
        },
        component: () =>
          import("../inventorymanagement/CreateInventoryplan.vue"),
      },
      {
        path: "/CreateAgainInventory",
        name: "CreateAgainInventory",
        meta: {
          title: "创建复盘",
          parentPath: "/toAgainInventoryList",
          parentTitle: "待复盘列表",
          parentName: "toAgainInventoryList",
          refreshPath: "/",
        },
        component: () =>
          import("../inventorymanagement/CreateAgainInventory.vue"),
      },
      {
        path: "/effectiveMaterialList",
        name: "effectiveMaterialList",
        meta: {
          title: "有效期物料列表",
          refreshPath: "/",
        },
        component: () =>
          import("../effectivematerialmanagement/EffectiveMateriaLlist.vue"),
      },
      {
        path: "/effectiveMaterialImport",
        name: "effectiveMaterialImport",
        meta: {
          title: "导入有效期物料",
          parentPath: "/effectiveMaterialList",
          parentTitle: "有效期物料列表",
          parentName: "effectiveMaterialList",
          refreshPath: "/",
        },
        component: () =>
          import("../effectivematerialmanagement/effectiveMaterialImport.vue"),
      },
      {
        path: "/samestorerelocation",
        name: "samestorerelocation",
        meta: {
          title: "同库房移库",
          refreshPath: "/",
        },
        component: () =>
          import("../relocationmanagement/Samestorerelocation.vue"),
      },
      {
        path: "/supplierlist",
        name: "supplierlist",
        meta: {
          title: "供应商列表",
          refreshPath: "/",
        },
        component: () => import("../supplierManagement/supplierList.vue"),
      },
      {
        path: "/supplierdetail",
        name: "supplierdetail",
        meta: {
          title: "供应商详情",
          parentPath: "/supplierlist",
          parentTitle: "供应商列表",
          parentName: "supplierlist",
          refreshPath: "/",
        },
        component: () => import("../supplierManagement/supplierDetail.vue"),
      },
      {
        path: "/receiverlist",
        name: "receiverlist",
        meta: {
          title: "收货单列表",
          refreshPath: "/",
        },
        component: () => import("../receiverManagement/receiverList.vue"),
      },
      {
        path: "/offlineReceiving",
        name: "offlineReceiving",
        meta: {
          title: "线下收货",
          parentPath: "/receiverlist",
          parentTitle: "收货单列表",
          parentName: "receiverlist",
          refreshPath: "/",
        },
        component: () => import("../receiverManagement/offlineReceiving.vue"),
      },
      {
        path: "/receiverofinvoicedetail",
        name: "receiverofinvoicedetail",
        meta: {
          title: "发货单收货详情",
          parentPath: "/receiverlist",
          parentTitle: "收货单列表",
          parentName: "receiverlist",
          refreshPath: "/",
        },
        component: () =>
          import("../receiverManagement/receiptOfInvoiceDetail.vue"),
      },
      {
        path: "/returngoodslist",
        name: "returngoodslist",
        meta: {
          title: "退货单列表",
          refreshPath: "/",
        },
        component: () => import("../receiverManagement/returnGoodsList.vue"),
      },
      {
        path: "/returngoodsdetail",
        name: "returngoodsdetail",
        meta: {
          title: "退货单详情",
          parentPath: "/returngoodslist",
          parentTitle: "退货单列表",
          parentName: "returngoodslist",
          refreshPath: "/",
        },
        component: () => import("../receiverManagement/returnGoodsDetail.vue"),
      },
      {
        path: "/createreturngoods",
        name: "createreturngoods",
        meta: {
          title: "创建退货单",
          parentPath: "/returngoodslist",
          parentTitle: "退货单列表",
          parentName: "returngoodslist",
          refreshPath: "/",
        },
        component: () => import("../receiverManagement/createReturnGoods.vue"),
      },
      {
        path: "/modifyreturngoods",
        name: "modifyreturngoods",
        meta: {
          title: "修改退货单",
          parentPath: "/returngoodslist",
          parentTitle: "退货单列表",
          parentName: "returngoodslist",
          refreshPath: "/",
        },
        component: () => import("../receiverManagement/modifyReturnGoods.vue"),
      },
      {
        path: "/materialreturnlist",
        name: "materialreturnlist",
        meta: {
          title: "退料单列表",
          refreshPath: "/",
        },
        component: () => import("../receiverManagement/materialReturnList.vue"),
      },
      {
        path: "/materialreturndetail",
        name: "materialreturndetail",
        meta: {
          title: "退料单详情",
          parentPath: "/materialreturnlist",
          parentTitle: "退料单列表",
          parentName: "materialreturnlist",
          refreshPath: "/",
        },
        component: () =>
          import("../receiverManagement/materialReturnDetail.vue"),
      },
      {
        path: "/creatematerialreturn",
        name: "creatematerialreturn",
        meta: {
          title: "创建退料单",
          parentPath: "/materialreturnlist",
          parentTitle: "退料单列表",
          parentName: "materialreturnlist",
          refreshPath: "/",
        },
        component: () =>
          import("../receiverManagement/createMaterialReturn.vue"),
      },
      {
        path: "/modifymaterialreturn",
        name: "modifymaterialreturn",
        meta: {
          title: "修改退料单",
          parentPath: "/materialreturnlist",
          parentTitle: "退料单列表",
          parentName: "materialreturnlist",
          refreshPath: "/",
        },
        component: () =>
          import("../receiverManagement/modifyMaterialReturn.vue"),
      },
      {
        path: "/longmateriallist",
        name: "longmateriallist",
        meta: {
          title: "长账龄物料列表",
          refreshPath: "/",
        },
        component: () =>
          import("../longMaterialManagement/longMaterialList.vue"),
      },
      {
        path: "/importlongmaterial",
        name: "importlongmaterial",
        meta: {
          title: "导入长账龄物料",
          parentPath: "/longmateriallist",
          parentTitle: "长账龄物料列表",
          parentName: "longmateriallist",
          refreshPath: "/",
        },
        component: () =>
          import("../longMaterialManagement/importLongMaterial.vue"),
      },
      {
        path: "/longmaterialdetail",
        name: "longmaterialdetail",
        meta: {
          title: "长账龄物料详情",
          parentPath: "/longmateriallist",
          parentTitle: "长账龄物料列表",
          parentName: "longmateriallist",
          refreshPath: "/",
        },
        component: () =>
          import("../longMaterialManagement/longMaterialDetail.vue"),
      },
      {
        path: "/toorderlist",
        name: "toorderlist",
        meta: {
          title: "待下单订单列表",
          refreshPath: "/",
        },
        component: () =>
          import("../views/ordermanagement/toorderlist/toorderlist.vue"),
      },
      {
        path: "/todetailsOrder",
        name: "todetailsOrder",
        meta: {
          title: "订单详情",
          parentPath: "/toorderlist",
          parentTitle: "待下单订单列表",
          parentName: "toorderlist",
          refreshPath: "/",
        },
        component: () =>
          import("../views/ordermanagement/toorderlist/detailsOrder.vue"),
      },
      {
        path: "/updateOrder",
        name: "updateOrder",
        meta: {
          title: "修改订单",
          parentPath: "/toorderlist",
          parentTitle: "待下单订单列表",
          parentName: "toorderlist",
          refreshPath: "/",
        },
        component: () =>
          import("../views/ordermanagement/toorderlist/updateOrder.vue"),
      },
      {
        path: "/splitOrder",
        name: "splitOrder",
        meta: {
          title: "创建订单",
          parentPath: "/toorderlist",
          parentTitle: "待下单订单列表",
          parentName: "toorderlist",
          refreshPath: "/",
        },
        component: () =>
          import("../views/ordermanagement/toorderlist/splitOrder.vue"),
      },
      {
        path: "/orderlist",
        name: "orderlist",
        meta: {
          title: "订单列表",
          refreshPath: "/",
        },
        component: () =>
          import("../views/ordermanagement/orderlist/orderlist.vue"),
      },
      {
        path: "/createOrder",
        name: "createOrder",
        meta: {
          title: "创建订单",
          parentPath: "/orderlist",
          parentTitle: "订单列表",
          parentName: "orderlist",
          refreshPath: "/",
        },
        component: () =>
          import("../views/ordermanagement/orderlist/createOrder.vue"),
      },
      {
        path: "/detailsOrder",
        name: "detailsOrder",
        meta: {
          title: "订单详情",
          parentPath: "/orderlist",
          parentTitle: "订单列表",
          parentName: "orderlist",
          refreshPath: "/",
        },
        component: () =>
          import("../views/ordermanagement/orderlist/detailsOrder.vue"),
      },
      {
        path: "/workshopaccountlist",
        name: "workshopaccountlist",
        meta: {
          title: "车间会计科目管理",
          refreshPath: "/",
        },
        component: () =>
          import("../views/workshopaccountmanagement/workshopaccountlist.vue"),
      },
      {
        path: "/updateWorkshopaccount",
        name: "updateWorkshopaccount",
        meta: {
          title: "修改车间会计科目",
          parentPath: "/workshopaccountlist",
          parentTitle: "车间会计科目管理",
          parentName: "workshopaccountlist",
          refreshPath: "/",
        },
        component: () =>
          import(
            "../views/workshopaccountmanagement/updateWorkshopaccount.vue"
          ),
      },
      {
        path: "/addWorkshopaccount",
        name: "addWorkshopaccount",
        meta: {
          title: "追加车间会计科目",
          parentPath: "/workshopaccountlist",
          parentTitle: "车间会计科目管理",
          parentName: "workshopaccountlist",
          refreshPath: "/",
        },
        component: () =>
          import("../views/workshopaccountmanagement/addWorkshopaccount.vue"),
      },
      {
        path: "/noticelist",
        name: "noticelist",
        meta: {
          title: "公告管理",
          refreshPath: "/",
        },
        component: () => import("../views/noticemanagement/noticelist.vue"),
      },
      {
        path: "/detailsNotice",
        name: "detailsNotice",
        meta: {
          title: "公告详情",
          parentPath: "/noticelist",
          parentTitle: "公告管理",
          parentName: "noticelist",
          refreshPath: "/",
        },
        component: () => import("../views/noticemanagement/detailsNotice.vue"),
      },
      {
        path: "/releaseNotice",
        name: "releaseNotice",
        meta: {
          title: "添加公告",
          parentPath: "/noticelist",
          parentTitle: "公告管理",
          parentName: "noticelist",
          refreshPath: "/",
        },
        component: () => import("../views/noticemanagement/releaseNotice.vue"),
      },
      {
        path: "/updateNotice",
        name: "updateNotice",
        meta: {
          title: "修改公告",
          parentPath: "/noticelist",
          parentTitle: "公告管理",
          parentName: "noticelist",
          refreshPath: "/",
        },
        component: () => import("../views/noticemanagement/updateNotice.vue"),
      },
      {
        path: "/DeliverySummaryReport",
        name: "DeliverySummaryReport",
        meta: {
          title: "出库汇总报告",
          refreshPath: "/",
        },
        component: () =>
          import("../views/reportmanagement/DeliverySummaryReport.vue"),
      },
      {
        path: "/CollectionList",
        name: "CollectionList",
        meta: {
          title: "领用清单",
          refreshPath: "/",
        },
        component: () =>
          import("../views/reportmanagement/CollectionList.vue"),
      },
      {
        path: "/InventorySummaryList",
        name: "InventorySummaryList",
        meta: {
          title: "盘点汇总",
          refreshPath: "/",
        },
        component: () =>
          import("../views/reportmanagement/InventorySummaryList.vue"),
      },
      {
        path: "/ReceiptSummaryReport",
        name: "ReceiptSummaryReport",
        meta: {
          title: "入库汇总报告",
          refreshPath: "/",
        },
        component: () =>
          import("../views/reportmanagement/ReceiptSummaryReport.vue"),
      },
      {
        path: "/exportreport",
        name: "exportreport",
        meta: {
          title: "入库明细报告",
          refreshPath: "/",
        },
        component: () => import("../views/reportmanagement/exportreport.vue"),
      },
      {
        path: "/outStoreDetailReport",
        name: "outStoreDetailReport",
        meta: {
          title: "出库明细报告",
          refreshPath: "/",
        },
        component: () =>
          import("../views/reportmanagement/outStoreDetailReport.vue"),
      },
      {
        path: "/InventoryReport",
        name: "InventoryReport",
        meta: {
          title: "盘点报告",
          refreshPath: "/",
        },
        component: () =>
          import("../views/reportmanagement/InventoryReport.vue"),
      },
      {
        path: "/inventoryReceiptIissueBalance",
        name: "inventoryReceiptIissueBalance",
        meta: {
          title: "存货出入库余额",
          refreshPath: "/",
        },
        component: () =>
          import("../views/reportmanagement/inventoryReceiptIissueBalance.vue"),
      },
      
      {
        path: "/pickingtodeliverylist",
        name: "pickingtodeliverylist",
        meta: {
          title: "领料单待发货列表",
          refreshPath: "/",
        },
        component: () =>
          import(
            "../views/deliverymanagement/pickingtodelivery/pickingtodeliverylist.vue"
          ),
      },
      {
        path: "/createNumber",
        name: "createNumber",
        meta: {
          title: "创建发货单",
          parentPath: "/pickingtodeliverylist",
          parentTitle: "领料单待发货列表",
          parentName: "pickingtodeliverylist",
          refreshPath: "/",
        },
        component: () =>
          import(
            "../views/deliverymanagement/pickingtodelivery/createNumber.vue"
          ),
      },
      {
        path: "/pickingdeliverylist",
        name: "pickingdeliverylist",
        meta: {
          title: "领料单发货列表",
          refreshPath: "/",
        },
        component: () =>
          import(
            "../views/deliverymanagement/pickingdelivery/pickingdeliverylist.vue"
          ),
      },
      {
        path: "/pickingdeliveryUpdate",
        name: "pickingdeliveryUpdate",
        meta: {
          title: "发货单详情",
          parentPath: "/pickingdeliverylist",
          parentTitle: "领料单发货列表",
          parentName: "pickingdeliverylist",
          refreshPath: "/",
        },
        component: () =>
          import(
            "../views/deliverymanagement/pickingdelivery/pickingdeliveryUpdate.vue"
          ),
      },
      {
        path: "/pickingdeliveryDetails",
        name: "pickingdeliveryDetails",
        meta: {
          title: "修改发货单",
          parentPath: "/pickingdeliverylist",
          parentTitle: "领料单发货列表",
          parentName: "pickingdeliverylist",
          refreshPath: "/",
        },
        component: () =>
          import(
            "../views/deliverymanagement/pickingdelivery/pickingdeliveryDetails.vue"
          ),
      },
      {
        path: "/srreturnlist",
        name: "srreturnlist",
        meta: {
          title: "以旧换新列表",
          refreshPath: "/",
        },
        component: () =>
          import(
            "../views/deliverymanagement/srreturnlist.vue"
          ),
      },
      {
        path: "/allocationtodeliverylist",
        name: "allocationtodeliverylist",
        meta: {
          title: "调拨单待发货列表",
          refreshPath: "/",
        },
        component: () =>
          import(
            "../views/deliverymanagement/allocationtodelivery/allocationtodeliverylist.vue"
          ),
      },
      {
        path: "/addNumber",
        name: "addNumber",
        meta: {
          title: "创建发货单",
          parentPath: "/allocationtodeliverylist",
          parentTitle: "调拨单待发货列表",
          parentName: "allocationtodeliverylist",
          refreshPath: "/",
        },
        component: () =>
          import(
            "../views/deliverymanagement/allocationtodelivery/addNumber.vue"
          ),
      },
      {
        path: "/allocationdeliverylist",
        name: "allocationdeliverylist",
        meta: {
          title: "调拨单发货列表",
          refreshPath: "/",
        },
        component: () =>
          import(
            "../views/deliverymanagement/allocationdelivery/allocationdeliverylist.vue"
          ),
      },
      {
        path: "/allocationdeliveryDetails",
        name: "allocationdeliveryDetails",
        meta: {
          title: "发货单详情",
          parentPath: "/allocationdeliverylist",
          parentTitle: "调拨单发货列表",
          parentName: "allocationdeliverylist",
          refreshPath: "/",
        },
        component: () =>
          import(
            "../views/deliverymanagement/allocationdelivery/allocationdeliveryDetails.vue"
          ),
      },
      {
        path: "/allocationdeliverylUpdate",
        name: "allocationdeliverylUpdate",
        meta: {
          title: "修改发货单",
          parentPath: "/allocationdeliverylist",
          parentTitle: "调拨单发货列表",
          parentName: "allocationdeliverylist",
          refreshPath: "/",
        },
        component: () =>
          import(
            "../views/deliverymanagement/allocationdelivery/allocationdeliverylUpdate.vue"
          ),
      },
      {
        path: "/allocationlist",
        name: "allocationlist",
        meta: {
          title: "调拨申请列表",
          refreshPath: "/",
        },
        component: () =>
          import("../views/allocationmanagement/allocationlist.vue"),
      },
      {
        path: "/detailsAllocation",
        name: "detailsAllocation",
        meta: {
          title: "调拨单详细",
          parentPath: "/allocationlist",
          parentTitle: "调拨申请列表",
          parentName: "allocationlist",
          refreshPath: "/",
        },
        component: () =>
          import("../views/allocationmanagement/detailsAllocation.vue"),
      },
      {
        path: "/createAllocation",
        name: "createAllocation",
        meta: {
          title: "调拨单创建",
          parentPath: "/allocationlist",
          parentTitle: "调拨单创建",
          parentName: "allocationlist",
          refreshPath: "/",
        },
        component: () =>
          import("../views/allocationmanagement/createAllocation.vue"),
      },
      {
        path: "/modifyAllocation",
        name: "modifyAllocation",
        meta: {
          title: "调拨单修改",
          parentPath: "/allocationlist",
          parentTitle: "调拨申请列表",
          parentName: "allocationlist",
          refreshPath: "/",
        },
        component: () =>
          import("../views/allocationmanagement/modifyAllocation.vue"),
      },

      {
        path: "/pickinglist",
        name: "pickinglist",
        meta: {
          title: "领料申请单列表",
          refreshPath: "/",
        },
        component: () => import("../views/pickingmanagement/pickinglist.vue"),
      },
      {
        path: "/addPicking",
        name: "addPicking",
        meta: {
          title: "追加领料单",
          parentPath: "/pickinglist",
          parentTitle: "领料列表",
          parentName: "pickinglist",
          refreshPath: "/",
        },
        component: () => import("../views/pickingmanagement/addPicking.vue"),
      },
      {
        path: "/detailsPicking",
        name: "detailsPicking",
        meta: {
          title: "领料单详情",
          parentPath: "/pickinglist",
          parentTitle: "领料列表",
          parentName: "pickinglist",
          refreshPath: "/",
        },
        component: () =>
          import("../views/pickingmanagement/detailsPicking.vue"),
      },
      {
        path: "/updatePicking",
        name: "updatePicking",
        meta: {
          title: "修改领料单",
          parentPath: "/pickinglist",
          parentTitle: "领料列表",
          parentName: "pickinglist",
          refreshPath: "/",
        },
        component: () => import("../views/pickingmanagement/updatePicking.vue"),
      },
      {
        path: "/contractlist",
        name: "contractlist",
        meta: {
          title: "合同列表",
          refreshPath: "/",
        },
        component: () => import("../views/contractmanagement/contractlist.vue"),
      },
      {
        path: "/importContract",
        name: "importContract",
        meta: {
          title: "导入数据",
          parentPath: "/contractlist",
          parentTitle: "合同列表",
          parentName: "contractlist",
          refreshPath: "/",
        },
        component: () =>
          import("../views/contractmanagement/importContract.vue"),
      },
      {
        path: "/detailsContract",
        name: "detailsContract",
        meta: {
          title: "合同详情",
          parentPath: "/contractlist",
          parentTitle: "合同列表",
          parentName: "contractlist",
          refreshPath: "/",
        },
        component: () =>
          import("../views/contractmanagement/detailsContract.vue"),
      },
      {
        path: "/dashboardDetail",
        name: "dashboardDetail",
        meta: {
          title: "公告详情",
          parentPath: "/dashboard",
          parentTitle: "首页",
          parentName: "dashboard",
          refreshPath: "/",
        },
        component: () => import("../views/DashboardDetail.vue"),
      },
      {
        path: "/loglist",
        name: "loglist",
        meta: {
          title: "日志列表",
          refreshPath: "/",
        },
        component: () => import("../views/logmanagement/loglist.vue"),
      },
      {
        path: "/PurMallLogList",
        name: "PurMallLogList",
        meta: {
          title: "商城推送日志",
          refreshPath: "/",
        },
        component: () => import("../views/logmanagement/PurMallLogList.vue"),
      },
      {
        path: "/undoNpiDataList",
        name: "undoNpiDataList",
        meta: {
          title: "Npi未读取数据列表",
          refreshPath: "/",
        },
        component: () => import("../views/logmanagement/undoNpiDataList.vue"),
      },
      {
        path: "/locationlist",
        name: "locationlist",
        meta: {
          title: "库位列表",
          refreshPath: "/",
        },
        component: () => import("../views/locationmanagement/locationlist.vue"),
      },
      {
        path: "/importLocation",
        name: "importLocation",
        meta: {
          title: "导入库位",
          parentPath: "/locationlist",
          parentTitle: "库位列表",
          parentName: "locationlist",
          refreshPath: "/",
        },
        component: () =>
          import("../views/locationmanagement/importLocation.vue"),
      },
      {
        path: "/addLocation",
        name: "addLocation",
        meta: {
          title: "添加库位",
          parentPath: "/locationlist",
          parentTitle: "库位列表",
          parentName: "locationlist",
          refreshPath: "/",
        },
        component: () => import("../views/locationmanagement/addLocation.vue"),
      },
      {
        path: "/updateLocation",
        name: "updateLocation",
        meta: {
          title: "修改库位",
          parentPath: "/locationlist",
          parentTitle: "库位列表",
          parentName: "locationlist",
          refreshPath: "/",
        },
        component: () =>
          import("../views/locationmanagement/updateLocation.vue"),
      },

      {
        path: "/dashboard",
        name: "dashboard",
        meta: {
          title: "首页",
        },
        component: () => import("../views/Dashboard.vue"),
      },
      {
        path: "/todolist",
        name: "todolist",
        meta: {
          title: "待办任务",
          refreshPath: "/",
        },
        component: () => import("../todoList/TodoList.vue"),
      },
      {
        path: "/keyMaterialApproval",
        name: "keyMaterialApproval",
        meta: {
          title: "关键性物料审批",
          parentPath: "/todolist",
          parentTitle: "待办任务",
          parentName: "todolist",
          refreshPath: "/",
        },
        component: () => import("../todoList/KeyMaterialApproval.vue"),
      },
      {
        path: "/paymentApproval",
        name: "paymentApproval",
        meta: {
          title: "付款审批",
          parentPath: "/todolist",
          parentTitle: "待办任务",
          parentName: "todolist",
          refreshPath: "/",
        },
        component: () => import("../todoList/paymentApproval.vue"),
      },
      {
        path: "/plandiffConfirmApproval",
        name: "plandiffConfirmApproval",
        meta: {
          title: "盘点报告确认差异审批",
          parentPath: "/todolist",
          parentTitle: "待办任务",
          parentName: "todolist",
          refreshPath: "/",
        },
        component: () => import("../todoList/plandiffConfirmApproval.vue"),
      },
      {
        path: "/accountAdjustApproval",
        name: "accountAdjustApproval",
        meta: {
          title: "调账审批",
          parentPath: "/todolist",
          parentTitle: "待办任务",
          parentName: "todolist",
          refreshPath: "/",
        },
        component: () => import("../todoList/accountAdjustApproval.vue"),
      },
      {
        path: "/dullMaterialApproval",
        name: "dullMaterialApproval",
        meta: {
          title: "呆滞物料申请审批",
          parentPath: "/todolist",
          parentTitle: "待办任务",
          parentName: "todolist",
          refreshPath: "/",
        },
        component: () => import("../todoList/dullMaterialApproval.vue"),
      },
      {
        path: "/materialRequisitionApproval",
        name: "materialRequisitionApproval",
        meta: {
          title: "领料单审批",
          parentPath: "/todolist",
          parentTitle: "待办任务",
          parentName: "todolist",
          refreshPath: "/",
        },
        component: () => import("../todoList/MaterialRequisitionApproval.vue"),
      },
      {
        path: "/approvalOrder",
        name: "approvalOrder",
        meta: {
          title: "订单审批",
          parentPath: "/todolist",
          parentTitle: "待办任务",
          parentName: "todolist",
          refreshPath: "/",
        },
        component: () => import("../todoList/approvalOrder.vue"),
      },
      {
        path: "/materialReturnApproval",
        name: "materialReturnApproval",
        meta: {
          title: "退料单审批",
          parentPath: "/todolist",
          parentTitle: "待办任务",
          parentName: "todolist",
          refreshPath: "/",
        },
        component: () => import("../todoList/MaterialReturnApproval.vue"),
      },
      {
        path: "/approvalOfTransferOrder",
        name: "approvalOfTransferOrder",
        meta: {
          title: "调拨单审批",
          parentPath: "/todolist",
          parentTitle: "待办任务",
          parentName: "todolist",
          refreshPath: "/",
        },
        component: () => import("../todoList/ApprovalOfTransferOrder.vue"),
      },
      {
        path: "/variousMaterialApproval",
        name: "variousMaterialApproval",
        meta: {
          title: "呆滞物料审批",
          parentPath: "/todolist",
          parentTitle: "待办任务",
          parentName: "todolist",
          refreshPath: "/",
        },
        component: () => import("../todoList/variousMaterialApproval.vue"),
      },
      {
        path: "/unavailableMaterialApproval",
        name: "unavailableMaterialApproval",
        meta: {
          title: "不可用物料处置申请详情",
          parentPath: "/todolist",
          parentTitle: "待办任务",
          parentName: "todolist",
          refreshPath: "/",
        },
        component: () =>
          import(
            "../todoList/ApplicationDetailsForDisposalOfUnavailableMaterials.vue"
          ),
      },
      {
        path: "/inventoryDetailApproval",
        name: "inventoryDetailApproval",
        meta: {
          title: "盘点计划盘点",
          parentPath: "/todolist",
          parentTitle: "待办任务",
          parentName: "todolist",
          refreshPath: "/",
        },
        component: () =>
          import(
            "../todoList/inventoryDetailApproval.vue"
          ),
      },
      {
        path: "/createInventoryplanApproval",
        name: "createInventoryplanApproval",
        meta: {
          title: "盘点计划提交",
          parentPath: "/todolist",
          parentTitle: "待办任务",
          parentName: "todolist",
          refreshPath: "/",
        },
        component: () =>
          import(
            "../todoList/createInventoryplanApproval.vue"
          ),
      },
      {
        path: "/paymentRecord",
        name: "paymentRecord",
        meta: {
          title: "付款列表",
          refreshPath: "/",
        },
        component: () => import("../paymentRecordManagement/paymentRecord.vue"),
      },
      {
        path: "/EditPaymentRecord",
        name: "EditPaymentRecord",
        meta: {
          title: "修改付款",
          parentPath: "/paymentRecord",
          parentTitle: "付款列表",
          parentName: "paymentRecord",
          refreshPath: "/",
        },
        component: () => import("../paymentRecordManagement/EditPaymentRecord.vue"),
      },
      {
        path: "/addPaymentRecord",
        name: "addPaymentRecord",
        meta: {
          title: "新增付款",
          parentPath: "/paymentRecord",
          parentTitle: "付款列表",
          parentName: "paymentRecord",
          refreshPath: "/",
        },
        component: () => import("../paymentRecordManagement/addPaymentRecord.vue"),
      },
      {
        path: "/detailPaymentRecord",
        name: "detailPaymentRecord",
        meta: {
          title: "付款详情",
          parentPath: "/paymentRecord",
          parentTitle: "付款列表",
          parentName: "paymentRecord",
          refreshPath: "/",
        },
        component: () => import("../paymentRecordManagement/detailPaymentRecord.vue"),
      },
      {
        path: "/costCenterList",
        name: "costCenterList",
        meta: {
          title: "成本中心列表",
          refreshPath: "/",
        },
        component: () => import("../locationManagement/costCenterList.vue"),
      },
      {
        path: "/stockTransferList",
        name: "stockTransferList",
        meta: {
          title: "移库履历",
          refreshPath: "/",
        },
        component: () => import("../locationManagement/stockTransferList.vue"),
      },
      {
        path: "/rfidList",
        name: "rfidList",
        meta: {
          title: "RFID小车列表",
          refreshPath: "/",
        },
        component: () => import("../locationManagement/rfidList.vue"),
      },
      {
        path: "/addCarlist",
        name: "addCarlist",
        meta: {
          title: "新增数据",
          parentPath: "/rfidList",
          parentTitle: "RFID小车列表",
          parentName: "rfidList",
          refreshPath: "/",
        },
        component: () => import("../locationManagement/addCarlist.vue"),
      },
      {
        path: "/lnventorylist",
        name: "lnventorylist",
        meta: {
          title: "库存列表",
          refreshPath: "/",
        },
        component: () => import("../locationManagement/Inventorylist.vue"),
      },
      {
        path: "/lnventoryModify",
        name: "lnventoryModify",
        meta: {
          title: "修改物料",
          parentPath: "/lnventorylist",
          parentTitle: "库存列表",
          parentName: "lnventorylist",
          refreshPath: "/",
        },
        component: () => import("../locationManagement/lnventoryModify.vue"),
      },
      {
        path: "/Inventorydetails",
        name: "Inventorydetails",
        meta: {
          title: "库存详情",
          parentPath: "/lnventorylist",
          parentTitle: "库存列表",
          parentName: "lnventorylist",
          refreshPath: "/",
        },
        component: () => import("../locationManagement/Inventorydetails.vue"),
      },
      {
        path: "/404",
        name: "404",
        meta: {
          title: "找不到页面",
          refreshPath: "/",
        },
        component: () => import("../views/404.vue"),
      },
      {
        path: "/403",
        name: "403",
        meta: {
          title: "没有权限",
          refreshPath: "/",
        },
        component: () => import("../views/403.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      title: "登录",
    },
    component: () => import("../views/Login.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | 非生产存货物料管理系统`;
  const token = sessionStorage.getItem("token");
  if (!token && to.path !== "/login") {
    next("/login");
  } else {
    if (from.path === "/") {
      next(to.meta.refreshPath);
    } else {
      if (
        Store._state.data.tagsList.some(
          (item) => item.name === to.name && item.path != to.path
        )
      ) {
        Store._state.data.tagsList.map((subItem) => {
          if (subItem.name === to.name && subItem.path != to.path) {
            //子项页面返回父项列表页面时
            if (from.path === subItem.path) {
              //判断是从子项页面来
              if (to.params.isBack) {
                const isBack = JSON.parse(to.params.isBack);
                if (isBack) {
                  next();
                }
              } else {
                next(subItem.path);
              }
            } else {
              next(subItem.path);
            }
          }
        });
      }
      next();
    }
  }
});

export default router;
